import {ComponentType} from 'react';
import {MessageUIComponentProps, MessageSimple} from 'stream-chat-react';
//styles
import './CustomMessage.scss';

const CustomMessage: ComponentType<MessageUIComponentProps> = (props) => {
  return (
    <>
      <MessageSimple {...props} />
    </>
  );
};

export default CustomMessage;
