// react
import {FC, useMemo} from 'react';
// types
import {TOpportunityStatus} from '../types';
import {OPPORTUNITY_VOLUNTEER_STATUS} from '@joc/api-gateway';
// icons
import {ReactComponent as CompletedIcon} from 'assets/image/volFilter/completed.svg';
//styles
import styles from '../VolunteerStatus.module.scss';

interface IVolunteerStatusIconProps {
  icon: string;
  isCompletedTab: boolean;
  iconVisible: boolean;
  volunteerOppoStatus: TOpportunityStatus;
}

export const VolunteerStatusIcon: FC<IVolunteerStatusIconProps> = ({
  icon,
  iconVisible,
  isCompletedTab,
  volunteerOppoStatus,
}) => {
  const isCompletedIcon = useMemo(
    () => volunteerOppoStatus !== OPPORTUNITY_VOLUNTEER_STATUS.CANT_MAKE_IT && isCompletedTab,
    [volunteerOppoStatus, isCompletedTab]
  );

  return isCompletedIcon ? (
    <CompletedIcon className={styles.status__icon} />
  ) : iconVisible ? (
    <img src={icon} className={styles.status__icon} alt="volunteer status icon" />
  ) : null;
};
