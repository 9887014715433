import {FC, useEffect} from 'react';
import styles from '../../OrganizationSetting.module.scss';
import {Field, Form, useFormikContext} from 'formik';
import Grades from '../Grades';
import Input from '../../../../../shared/inputs/Input';
import ButtonDefault from '../../../../../shared/components/Buttons/ButtonsDefault';
import {useTranslation} from 'react-i18next';
import {useYearlyGoalsContext} from '../context';

type YearlyGoalsFormProps = {
  schoolId?: string;
};

const YearlyGoalsForm: FC<YearlyGoalsFormProps> = ({schoolId}) => {
  const {setValues, isSubmitting, setSubmitting, isValid, dirty, values, setFieldValue} = useFormikContext<{
    gradeId: number;
    hourYearlyGoal: number;
    actYearlyGoal: number;
  }>();
  const {t} = useTranslation(['form', 'common', 'settings', 'buttons']);
  const {setGradesSettings, gradesSettings} = useYearlyGoalsContext();

  useEffect(() => {
    setGradesSettings(
      gradesSettings.map((grade) =>
        grade.gradeId === values.gradeId
          ? {...values, hourYearlyGoal: Number(values.hourYearlyGoal), actYearlyGoal: Number(values.actYearlyGoal)}
          : grade
      )
    );
  }, [values]);

  const onGradeChange = (id: number) => {
    const processGradeValues = gradesSettings.find((grade) => grade.gradeId === id);
    if (processGradeValues) {
      setFieldValue('hourYearlyGoal', processGradeValues.hourYearlyGoal).then();
      setFieldValue('actYearlyGoal', processGradeValues.actYearlyGoal).then();
    }

    setFieldValue('gradeId', id).then();
  };

  return (
    <Form
      onChange={() => {
        setSubmitting(false);
      }}
    >
      <div>
        {!!schoolId && (
          <div className={styles.organisationSettings__tab__form_item}>
            <label className={styles.organisationSettings__tab__form_item__label} htmlFor="gradeId">
              {t('form:schoolGrade')}
            </label>
            <Field
              name="gradeId"
              placeholder={t('settings:yearlyGoals.noGrade')}
              onChange={onGradeChange}
              component={Grades}
            />
          </div>
        )}
        <div className={styles.organisationSettings__tab__form_item}>
          <label className={styles.organisationSettings__tab__form_item__label} htmlFor="hourYearlyGoal">
            {t('common:time.hours')}
          </label>
          <Field
            name="hourYearlyGoal"
            placeholder="0"
            type="number"
            min="0"
            max={999999}
            isPencilHidden
            component={Input}
          />
        </div>
        <div className={styles.organisationSettings__tab__form_item}>
          <label className={styles.organisationSettings__tab__form_item__label} htmlFor="actYearlyGoal">
            {t('settings:yearlyGoals.actsOfChesed')}
          </label>
          <Field
            name="actYearlyGoal"
            placeholder="365"
            type="number"
            min="0"
            max={999999}
            isPencilHidden
            component={Input}
          />
        </div>
      </div>
      <div className={styles.button__wrapper}>
        <ButtonDefault
          classList={['secondary', 'lg']}
          clickHandler={() => setValues({gradeId: values.gradeId, actYearlyGoal: 365, hourYearlyGoal: 0})}
          title="Clear all"
          parentClassName={styles.button}
        />

        <ButtonDefault
          submitType
          title={t('buttons:button.save')}
          clickHandler={() => {}}
          disabled={isSubmitting || !isValid || !dirty}
          classList={['primary', 'lg']}
          parentClassName={styles.button}
        />
      </div>
    </Form>
  );
};

export default YearlyGoalsForm;
