import {Dispatch, FC, SetStateAction} from 'react';

import {Alert} from '@material-ui/lab';
import {Color} from '@material-ui/lab/Alert/Alert';
import {Snackbar as MUISnackbar} from '@material-ui/core';

// icons
// TODO: icons for each of Color
import {ReactComponent as SuccessIcon} from './success.svg';

import styles from './Snackbar.module.scss';

type SnackbarProps = {
  isOpenSnackbar: boolean;
  setIsOpenSnackbar: Dispatch<SetStateAction<boolean>>;
  title?: string;
  text: string;
  hideDuration?: number;
  severity?: Color;
};

const Snackbar: FC<SnackbarProps> = ({
  isOpenSnackbar,
  setIsOpenSnackbar,
  title,
  text,
  hideDuration,
  severity = 'success',
}: SnackbarProps) => {
  return (
    <MUISnackbar
      open={isOpenSnackbar}
      autoHideDuration={hideDuration}
      onClose={() => setIsOpenSnackbar(false)}
      className={styles.snackbar}
    >
      <Alert
        icon={<SuccessIcon />}
        onClose={() => setIsOpenSnackbar(false)}
        severity={severity}
        className={styles.snackbar__icon}
        style={{width: 290, padding: '0px 12px', border: '1px solid #5CC469', borderRadius: 8, background: '#DBF4E9'}}
        action={<></>}
      >
        <div className={styles.snackbar}>
          <div className={styles.snackbar__content}>
            {(title && <div className={styles.snackbar__title}>{title}</div>) || <></>}
            <span className={styles.snackbar__description}>{text}</span>
          </div>
        </div>
      </Alert>
    </MUISnackbar>
  );
};

export default Snackbar;
