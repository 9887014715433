import qs from 'qs';
//API
import {API} from 'core/API';
//urls
import {urls} from 'core/appUrls';
//types
import {IUserResponse, JoinToOrganisationByLinkRequest} from '@joc/api-gateway';

export const joinToOrganisationByLink = async (userData: IUserResponse, organisationId: string) => {
  const requestBody = JoinToOrganisationByLinkRequest.fromJS({
    email: userData.email,
    organisationId: Number(organisationId),
  });
  return await API.joinToOrganisationByLink(requestBody);
};

export const getClaimCongratulationsLink = async (organisationId: string, wasAttachedToOrg: boolean) => {
  const {organizationName, organisationGeneralType} = await API.getOrganisationById(Number(organisationId));
  const newQs = qs.stringify(
    {wasAttachedToOrg, organisationName: organizationName, organisationGeneralType},
    {addQueryPrefix: true}
  );
  return `${urls.onboardingClaimCongratulations}${newQs}`;
};
