import {useEffect, useMemo, useState} from 'react';
import {useChatContext} from 'stream-chat-react';
import {useTranslation} from 'react-i18next';
//redux
import {DefaultStreamChatGenerics} from '../CustomAvatar/types';
//API
import {API} from 'core/API';
//types
import {
  ORGANISATION_ACTIVE_STATUS,
  SearchOrganizationRequest,
  SearchVolunteersRequest,
  SEARCH_ORGANIZATION_POSSIBLE_SEARCH,
} from '@joc/api-gateway/lib/api-client';
import {ChannelsSearchResponse, CustomUserResponseType, UsersListType, userRole} from './types';
//helpers
import {getChannelsWithoutSoloChats} from './helpers';
//components
import InputSearch from 'shared/components/Inputs/InputSearch';
import {ChannelSearchResult} from './ChatSearchResult';
import {ChatSearchJocApiResult} from './ChatSearchJocApiResult';
import {ChatSearchNotFoundUser} from './ChatSearchNotFoundUser';
//styles
import './ChatSearch.global.scss';

const ChatSearch = () => {
  const {t} = useTranslation(['messages', 'buttons', 'inputs']);

  const {client, setActiveChannel} = useChatContext<DefaultStreamChatGenerics>();

  const [pagination] = useState({skip: 0, take: 6});
  const [searchQuery, setSearchQuery] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [usersList, setUsersList] = useState<UsersListType[]>();
  const [channels, setChannels] = useState<ChannelsSearchResponse>([]);

  useEffect(() => {
    if (!!searchQuery) findUsers(searchQuery.trim());
  }, [searchQuery]);

  const isVolunteer = useMemo(() => (client?.user as CustomUserResponseType)?.type === userRole.VOLUNTEER, [client]);
  const isOrganization = useMemo(
    () => (client?.user as CustomUserResponseType)?.type === userRole.ORGANIZATION,
    [client]
  );

  /**
   *
   * TODO: Rewrite and refactor
   */
  const findUsers = async (queryValue: string) => {
    setIsFetching(true);

    try {
      const allChannels = await client.queryChannels({
        name: {$autocomplete: queryValue},
        members: {$in: [client?.user?.id!]},
      });

      const channelsChats = getChannelsWithoutSoloChats(allChannels);
      setChannels(channelsChats);

      let usersListFromResponse: Array<UsersListType> = [];

      if (isVolunteer) {
        const organizationResponse = await API.findMyOrganisations(
          SearchOrganizationRequest.fromJS({
            pagination,
            fullTextSearch: {
              value: queryValue,
              fields: [SEARCH_ORGANIZATION_POSSIBLE_SEARCH.OrganizationName],
            },
          })
        );

        usersListFromResponse = organizationResponse.records
          .map(({chatId, organizationName, organizationLogoPath, organizationActiveStatus}) => ({
            id: chatId,
            name: organizationName,
            imagePath: organizationLogoPath,
            activeStatus: organizationActiveStatus.status,
          }))
          .filter((item) => item.activeStatus !== ORGANISATION_ACTIVE_STATUS.SUSPENDED);
      } else if (isOrganization) {
        const volunteersResponse = await API.searchVolunteers(
          undefined,
          SearchVolunteersRequest.fromJS({
            pagination,
            fullTextSearch: {value: queryValue, fields: ['firstName', 'lastName']},
          })
        );

        usersListFromResponse = volunteersResponse.records.map(
          ({chatId, firstName, lastName, imagePath, volunteerActiveStatus}) => ({
            id: chatId,
            name: `${firstName} ${lastName}`,
            imagePath,
            activeStatus: volunteerActiveStatus.status,
          })
        );
      } else {
        const volunteersResponse = await API.searchVolunteers(
          undefined,
          SearchVolunteersRequest.fromJS({
            pagination,
            fullTextSearch: {value: queryValue, fields: ['firstName', 'lastName']},
          })
        );

        const organizationResponse = await API.findOrganisation(
          SearchOrganizationRequest.fromJS({
            pagination,
            fullTextSearch: {
              value: queryValue,
              fields: [SEARCH_ORGANIZATION_POSSIBLE_SEARCH.OrganizationName],
            },
          })
        );

        usersListFromResponse = [
          ...volunteersResponse.records.map(({chatId, firstName, lastName, imagePath, volunteerActiveStatus}) => ({
            id: chatId,
            name: `${firstName} ${lastName}`,
            imagePath,
            activeStatus: volunteerActiveStatus.status,
          })),
          ...organizationResponse.records.map(
            ({chatId, organizationName, organizationLogoPath, organizationActiveStatus}) => ({
              id: chatId,
              name: organizationName,
              imagePath: organizationLogoPath,
              activeStatus: organizationActiveStatus.status,
            })
          ),
        ];
      }

      setUsersList(usersListFromResponse);
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
      console.error({error});
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <>
      <InputSearch
        placeholder={t('inputs:placeholders.search')}
        changeHandler={setSearchQuery}
        setIsInputFocused={setIsInputFocused}
      />
      {searchQuery && isInputFocused && (
        <div className="messaging-create-channel__user-results">
          {!!channels?.length || !!usersList?.length ? (
            <div>
              {channels.map((channel) => (
                <div key={channel.cid}>
                  <ChannelSearchResult channel={channel} setActiveChannel={setActiveChannel} />
                </div>
              ))}
              {usersList?.map((user) => (
                <div key={user.id}>
                  <ChatSearchJocApiResult
                    member={user}
                    userId={client.user?.id as string}
                    setActiveChannel={setActiveChannel}
                  />
                </div>
              ))}
            </div>
          ) : (
            !isFetching && <ChatSearchNotFoundUser />
          )}
        </div>
      )}
    </>
  );
};

export default ChatSearch;
