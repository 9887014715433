import {
  OPPORTUNITY_VOLUNTEER_STATUS,
  IOpportunityResponse,
  OPPORTUNITY_STATUSES,
  OPPORTUNITY_TYPES,
} from '@joc/api-gateway';
import {hasDatePassed} from 'core/functions';

export const getNewHistoryLocation = (volUpdateManualOpportunity: string, opportunityId: number) => {
  return {
    pathname: volUpdateManualOpportunity,
    search: `${opportunityId}`,
  };
};

const getStatusRejoinItem = (
  opportunity: IOpportunityResponse,
  isAdminManualOppo?: boolean,
  volunteerStatus?: OPPORTUNITY_VOLUNTEER_STATUS
) => {
  const {endDate} = opportunity;

  const isNotManualOppo = opportunity.opportunityType !== OPPORTUNITY_TYPES.MANUAL;

  const isReject = volunteerStatus === OPPORTUNITY_VOLUNTEER_STATUS.REJECT;

  const isCantMakeItCondition =
    (volunteerStatus === OPPORTUNITY_VOLUNTEER_STATUS.CANT_MAKE_IT && !hasDatePassed(endDate)) ||
    volunteerStatus !== OPPORTUNITY_VOLUNTEER_STATUS.CANT_MAKE_IT;

  const isAdmin = isAdminManualOppo && isCantMakeItCondition;

  const isNotAdmin = !isAdminManualOppo && !isReject && isCantMakeItCondition && isNotManualOppo;

  return isNotAdmin || isAdmin;
};

const getStatusEditItem = (opportunity: IOpportunityResponse, isAdminManualOppo?: boolean) => {
  return !isAdminManualOppo && opportunity.opportunityType === OPPORTUNITY_TYPES.MANUAL;
};

export const getDisplayedItems = (
  opportunity: IOpportunityResponse,
  volunteerStatus?: OPPORTUNITY_VOLUNTEER_STATUS,
  isAdminManualOppo?: boolean
) => {
  return {
    isVisibleRemindItem: opportunity.status === OPPORTUNITY_STATUSES.OPEN,
    isVisibleEditItem: getStatusEditItem(opportunity, isAdminManualOppo),
    isVisibleShareItem: opportunity.opportunityType !== OPPORTUNITY_TYPES.MANUAL,
    isVisibleDeleteItem: opportunity.opportunityType === OPPORTUNITY_TYPES.MANUAL && !isAdminManualOppo,
    isVisibleRejoinItem: getStatusRejoinItem(opportunity, isAdminManualOppo, volunteerStatus),
  };
};
