import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Thread, ThreadHeaderProps} from 'stream-chat-react';
//images
import {CloseThreadIcon} from '../../../RandomImage';
//styles
import './MessagingThread.css';

const MessagingThread: FC<ThreadHeaderProps> = ({closeThread, thread}) => {
  const {t} = useTranslation('chat');

  const getReplyCount = () => {
    if (!thread?.reply_count) return '';
    if (thread.reply_count === 1) return `1 ${t('reply')}`;
    return `${thread.reply_count} ${t('replies')}`;
  };

  return (
    <div className="custom-thread-header">
      <div className="custom-thread-header__left">
        <p className="custom-thread-header__left-title">{t('thread')}</p>
        <p className="custom-thread-header__left-count">{getReplyCount()}</p>
      </div>
      <CloseThreadIcon {...{closeThread}} />
    </div>
  );
};

export default MessagingThread;
