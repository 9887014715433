import i18next from 'i18next';
//types
import {OPPORTUNITY_VOLUNTEER_STATUS, IGetVolunteerByIdResponse} from '@joc/api-gateway';

export const getPendingVolunteersCount = (volunteers: IGetVolunteerByIdResponse[]) =>
  volunteers.filter((volunteer) => volunteer.status === OPPORTUNITY_VOLUNTEER_STATUS.PENDING).length;

export const getPendingVolunteersString = (volunteersCount: number) =>
  volunteersCount < 100 ? volunteersCount.toString() : '99+';

export const getPendingVolunteersTitle = (volunteersCount: number, isSchool: boolean) => {
  let firstPart: string;

  if (volunteersCount === 1) {
    firstPart = isSchool ? i18next.t('inviteColleagues:student') : i18next.t('inviteColleagues:volunteer');
  } else {
    firstPart = isSchool ? i18next.t('inviteColleagues:students') : i18next.t('inviteColleagues:volunteers');
  }

  const secondPart =
    volunteersCount === 1
      ? i18next.t('chesedOpportunities:isWaitingForApproval')
      : i18next.t('chesedOpportunities:areWaitingForApproval');

  return `${volunteersCount} ${firstPart} ${secondPart}`;
};

export const getFilteredVolunteers = (volunteers?: IGetVolunteerByIdResponse[], countVolunteersPreview?: number) => {
  return (
    volunteers?.filter(
      (volunteer) =>
        volunteer.status === OPPORTUNITY_VOLUNTEER_STATUS.CONFIRM ||
        volunteer.status === OPPORTUNITY_VOLUNTEER_STATUS.PENDING
    ) || []
  );
};
