import {Dispatch, FC, SetStateAction, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//redux
import {selectorGetSchoolId} from 'redux/organization-service/selector';
//types
import {
  IGetVolunteerByIdResponse,
  IOpportunityResponse,
  OPPORTUNITY_STATUSES,
  OPPORTUNITY_TYPES,
} from '@joc/api-gateway';
//helpers
import {
  getPendingVolunteersString,
  getPendingVolunteersCount,
  getPendingVolunteersTitle,
  getFilteredVolunteers,
} from './helpers';
//components
import PopupVolunteers from 'components/Organization/Opportunities/OpportunityPopups/PopupVolunteers';
import ButtonOutlined from 'shared/components/Buttons/ButtonOutlined';
import ImageWithPopup from 'shared/components/ImageWithPopup';
//styles
import styles from './VolunteerRenderer.module.scss';
import {VolunteerLimit} from 'shared/components/VolunteerLimit/VolunteerLimit';

type LotsVolunteersPropsType = {
  opportunityId: number;
  opportunityName: string;
  isOptionsDisable?: boolean;
  isApprovalRequired?: boolean;
  isShowVolunteerLimit?: boolean;
  isViewVolunteersDisable?: boolean;
  opportunity: IOpportunityResponse;
  opportunityType: OPPORTUNITY_TYPES;
  opportunityStatus: OPPORTUNITY_STATUSES;
  volunteersArray: Array<IGetVolunteerByIdResponse>;
  setIsPreviewOpen?: Dispatch<SetStateAction<boolean>>;
  activeVolunteers?: Array<IGetVolunteerByIdResponse>;
};

const LotsVolunteers: FC<LotsVolunteersPropsType> = ({
  opportunity,
  opportunityId,
  volunteersArray,
  opportunityName,
  opportunityType,
  activeVolunteers,
  setIsPreviewOpen,
  opportunityStatus,
  isApprovalRequired,
  isShowVolunteerLimit,
  isViewVolunteersDisable,
}: LotsVolunteersPropsType) => {
  const {t} = useTranslation(['buttons', 'popup']);

  const isSchool = !!useSelector(selectorGetSchoolId);
  const pendingVolunteersCount = getPendingVolunteersCount(volunteersArray);
  const pendingVolunteersString = getPendingVolunteersString(pendingVolunteersCount);
  const pendingVolunteersTitle = getPendingVolunteersTitle(pendingVolunteersCount, isSchool);

  const [countVolunteersPreview] = useState(3);
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);

  useEffect(() => {
    if (setIsPreviewOpen) setIsPreviewOpen(isShowPopup);
  }, [isShowPopup]);

  const isVisibleVolunteersLimit = useMemo(
    () => isShowVolunteerLimit && Boolean(opportunity.volunteersMaxCount),
    [isShowVolunteerLimit, opportunity]
  );

  const filteredActiveVolunteers = getFilteredVolunteers(activeVolunteers, countVolunteersPreview);

  const imagePopupTitle = useMemo(() => t(`popup:profilePicture.${isSchool ? 'student' : 'volunteer'}`), [isSchool]);
  const isShowImageCounter = useMemo(
    () => filteredActiveVolunteers.length > countVolunteersPreview,
    [filteredActiveVolunteers, countVolunteersPreview]
  );

  return (
    <div className={styles.volunteer}>
      <div className={styles.volunteer__info}>
        {!!filteredActiveVolunteers?.length && (
          <div
            className={cx(
              styles.volunteer__info__photos,
              filteredActiveVolunteers.slice(0, countVolunteersPreview).length < countVolunteersPreview &&
                styles.two_photos
            )}
          >
            {filteredActiveVolunteers.slice(0, countVolunteersPreview).map(({id, imagePath}) => (
              <ImageWithPopup
                key={id}
                imagePath={imagePath}
                popupTitle={imagePopupTitle}
                classNames={styles.volunteer__info__photo}
              />
            ))}
            {isShowImageCounter && (
              <span className={styles.volunteer__info__counter}>
                +{filteredActiveVolunteers.length - countVolunteersPreview}
              </span>
            )}
          </div>
        )}
      </div>
      {isVisibleVolunteersLimit && (
        <div className={styles.volunteersLimit}>
          <VolunteerLimit
            maxVolunteers={opportunity.volunteersMaxCount || 0}
            currentVolunteers={opportunity.volunteersCount || 0}
          />
        </div>
      )}
      {!isViewVolunteersDisable && (
        <div className={styles.volunteer__options}>
          <ButtonOutlined
            title={t('buttons:button.viewMore')}
            clickHandler={() => setIsShowPopup(true)}
            disabled={isViewVolunteersDisable}
          />
          {pendingVolunteersCount ? (
            <div className={styles.volunteer__options_pending} title={pendingVolunteersTitle}>
              {pendingVolunteersString}
            </div>
          ) : null}
        </div>
      )}
      {isShowPopup && (
        <PopupVolunteers
          opportunity={opportunity}
          setIsShowPopup={setIsShowPopup}
          opportunityId={opportunityId}
          volunteersArray={volunteersArray}
          opportunityName={opportunityName}
          opportunityStatus={opportunityStatus}
          opportunityType={opportunityType}
          isApprovalRequired={isApprovalRequired}
        />
      )}
    </div>
  );
};

export default LotsVolunteers;
