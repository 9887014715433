import i18n from 'components/translate';
import {ORGANIZATION_SETTINGS_TABS} from './interfaces';

export const ORG_SETTINGS_TABS_TITLES = {
  logYourChesed: i18n.t('settings:logYourChased.title'),
  yearlyGoals: i18n.t('settings:yearlyGoals.title'),
  info: i18n.t('settings:info.title'),
};

export const ORG_SETTINGS_TABS = [
  {
    title: ORG_SETTINGS_TABS_TITLES.info,
    currentTab: ORGANIZATION_SETTINGS_TABS.Info,
  },
  {
    title: ORG_SETTINGS_TABS_TITLES.logYourChesed,
    currentTab: ORGANIZATION_SETTINGS_TABS.LogYourChesed,
  },
  {
    title: ORG_SETTINGS_TABS_TITLES.yearlyGoals,
    currentTab: ORGANIZATION_SETTINGS_TABS.YearlyGoals,
  },
];
