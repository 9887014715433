import {FC, useState} from 'react';
import {connect, ConnectedProps, useSelector} from 'react-redux';
import {NavLink, useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
import firebase from 'firebase';
//redux
import {Store} from 'redux/root';
import {
  selectorGetHiddenNavbarModeState,
  selectorGetUserRolesByOrg,
  selectorIsUserMarketer,
} from 'redux/user-service/selector';
import {selectorGetOrgActiveStatus, selectorGetSchoolId} from 'redux/organization-service/selector';
import {resetOrgInfo} from 'redux/organization-service/actions';
import {deleteUserNotificationToken, userResetData} from 'redux/user-service/actions';
import {chatClient, resetChat} from 'redux/chat-service/actions';
import {selectorGetChatUnreadMessagesCount} from 'redux/chat-service/selector';
//urls
import {urls} from 'core/appUrls';
//types
import {ORGANISATION_ACTIVE_STATUS, VOLUNTEER} from '@joc/api-gateway';
//images
import LogoutSvg from 'assets/image/navbar/logout.svg';
import DashboardSvg from 'assets/image/navbar/dashboard.svg';
import InboxSvg from 'assets/image/navbar/inbox.svg';
import MarketingSvg from 'assets/image/navbar/marketing.svg';
import VolunteersSvg from 'assets/image/navbar/volunteers.svg';
import StatisticsSvg from 'assets/image/navbar/statistics.svg';
import OpportunitiesSvg from 'assets/image/navbar/chesed-opportunites.svg';
import StudentSvg from 'assets/image/student.svg';

//components
import NavBarItem from './NavBarItem';
//styles
import styles from './index.module.scss';

const Navbar: FC<NavbarProps> = ({
  hiddenState,
  schoolId,
  resetChat,
  resetOrgInfo,
  userResetData,
  deleteUserNotificationToken,
  orgStatus,
  chatUnreadMessagesCount,
}: NavbarProps) => {
  const [opportunitiesActive] = useState(false);

  const isMarketer = useSelector(selectorIsUserMarketer);
  const userRolesByOrg = useSelector(selectorGetUserRolesByOrg);

  const history = useHistory();

  const {t} = useTranslation([
    'dashboard',
    'chesedOpportunities',
    'students',
    'common',
    'statistics',
    'marketing',
    'volunteers',
    'members',
    'chat',
  ]);

  const menu = {
    dashboard: {icon: DashboardSvg, title: t('dashboard:title'), link: urls.orgDashboard},
    opportunities: {
      icon: OpportunitiesSvg,
      title: t('chesedOpportunities:title'),
      link: urls.orgOpportunities,
    },
    students: {title: t('students:title')},
    volunteers: {icon: StudentSvg, title: t('volunteers:title'), link: urls.orgVolunteers},
    members: {icon: VolunteersSvg, title: t('members:title'), link: urls.orgMembers},
    marketing: {icon: MarketingSvg, title: t('marketing:title'), link: urls.orgMarketing},
    inbox: {icon: InboxSvg, title: t('chat:title'), link: urls.orgInbox},
    statistics: {icon: StatisticsSvg, title: t('statistics:title'), link: urls.orgStatistics},
    logout: {icon: LogoutSvg, title: t('common:titles.logout')},
  };

  const logoutClickHandler = async () => {
    try {
      await deleteUserNotificationToken();
      userResetData();
      resetOrgInfo();
      resetChat();
      setTimeout(() => {}, 500);
      await firebase.auth().signOut();
      localStorage.clear();
      setTimeout(() => {
        history.push(urls.onboardingChooseUserType);
        chatClient.disconnectUser();
      }, 500);
    } catch (error) {
      console.error(error);
    }
  };

  const isSuspended = orgStatus?.status === ORGANISATION_ACTIVE_STATUS.SUSPENDED;

  return (
    <div
      className={cx(styles.sidebar, {
        [styles.sidebar__hide]: hiddenState,
      })}
    >
      <nav className={cx(styles.sidebar__nav, isMarketer && styles.sidebar__nav_small)}>
        <NavLink exact to={menu.dashboard.link} className={styles.sidebar__item} activeClassName={styles.active}>
          <NavBarItem icon={menu.dashboard.icon} title={menu.dashboard.title} />
        </NavLink>

        {!isMarketer && (
          <NavLink
            exact
            to={menu.opportunities.link}
            className={cx(styles.sidebar__item, opportunitiesActive && styles.active)}
            activeClassName={styles.active}
          >
            <NavBarItem icon={menu.opportunities.icon} title={menu.opportunities.title} />
          </NavLink>
        )}
        <hr />
        {!isMarketer && (
          <NavLink exact to={menu.volunteers.link} className={styles.sidebar__item} activeClassName={styles.active}>
            <NavBarItem icon={menu.volunteers.icon} title={!!schoolId ? menu.students.title : menu.volunteers.title} />
          </NavLink>
        )}
        {!!userRolesByOrg?.filter((i) => i.roleName === VOLUNTEER.ADMIN).length && (
          <NavLink exact to={menu.members.link} className={styles.sidebar__item} activeClassName={styles.active}>
            <NavBarItem icon={menu.members.icon} title={menu.members.title} />
          </NavLink>
        )}
        {!!userRolesByOrg?.filter((i) => i.roleName !== VOLUNTEER.COORDINATOR).length && (
          <NavLink exact to={menu.marketing.link} className={styles.sidebar__item} activeClassName={styles.active}>
            <NavBarItem icon={menu.marketing.icon} title={menu.marketing.title} />
          </NavLink>
        )}
        <NavLink
          exact
          to={menu.inbox.link}
          className={cx(styles.sidebar__item, {
            [styles.sidebar__item__disabled]: isSuspended,
          })}
          activeClassName={styles.active}
        >
          <NavBarItem
            icon={menu.inbox.icon}
            title={!isSuspended ? menu.inbox.title : `${menu.inbox.title} ${t('common:suspended')}`}
            counterIndicatorValue={chatUnreadMessagesCount}
          />
        </NavLink>
        <NavLink exact to={menu.statistics.link} className={styles.sidebar__item} activeClassName={styles.active}>
          <NavBarItem icon={menu.statistics.icon} title={menu.statistics.title} />
        </NavLink>
      </nav>
      <div
        className={cx(styles.logout, {
          [styles.logout__hide]: hiddenState,
        })}
        onClick={logoutClickHandler}
      >
        <NavBarItem icon={menu.logout.icon} title={menu.logout.title} />
      </div>
    </div>
  );
};

const mapStateToProps = (store: Store) => ({
  hiddenState: selectorGetHiddenNavbarModeState(store),
  orgStatus: selectorGetOrgActiveStatus(store),
  schoolId: selectorGetSchoolId(store),
  chatUnreadMessagesCount: selectorGetChatUnreadMessagesCount(store),
});

const mapDispatchToProps = {
  userResetData,
  resetChat,
  resetOrgInfo,
  deleteUserNotificationToken,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type NavbarProps = ConnectedProps<typeof connector>;

export default connector(Navbar);
