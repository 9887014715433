import {FC, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {TranslationLanguages} from 'stream-chat';
import {
  Chat,
  Channel,
  ChannelList,
  MessageList,
  MessageInput,
  Window,
  Streami18n,
  LoadMorePaginator,
  LoadMoreButton,
  Thread,
} from 'stream-chat-react';
import cx from 'classnames';
import qs from 'qs';
//types
import {ChatMember, InitChatRequestInfoType} from 'core/types';
import {MessagesFilterOption} from './types';
//redux
import {selectorGetChatId} from 'redux/user-service/selector';
import {selectorGetError} from 'redux/error-service/selector';
import {selectorGetOrgChatId, selectorGetOrgName} from 'redux/organization-service/selector';
import {useHistory, useLocation} from 'react-router';
import {resetError, setError} from 'redux/error-service/action';
import {chatClient} from 'redux/chat-service/actions';
import {selectorGetChatUserToConnect, selectorGetUserConnectionState} from 'redux/chat-service/selector';
//translations
import 'moment/locale/he';
import moment from 'moment';
import heTranslation from 'locales/he/streamchat/streamchat.json';
//context
import {MessagesFilterContextProvider, getMessagesContextValue} from './context';
import UserChatAvatar from './ChatComponents/UserChatAvatar';
import EmojiPicker from '@emoji-mart/react';
import {SearchIndex} from 'emoji-mart';
//helpers
import {
  defaultMessagesFilterOptions,
  getChannelRenderFilterFn,
  getFilters,
  sort,
  options,
  getIsUnread,
} from './helpers';
//components
import MessagesFilter from './ChatComponents/MessagesFilter';
import Loader from '../Loader';
import ResponseFailure from '../ResponseFailure';
import {CustomTypingIndicator} from './ChatComponents/CustomTypingIndicator';
import CustomEmojiPicker from './ChatComponents/EmojiPicker/CustomEmojiPicker';
import {
  CreateChannel,
  CustomMessage,
  MessagingChannelHeader,
  MessagingChannelList,
  MessagingChannelPreview,
  MessagingInput,
  MessagingThread,
} from './ChatComponents';
//styles
import 'stream-chat-react/dist/css/index.css';
import './Chat.global.scss';
import styles from './Chat.module.scss';

type ChatOkProps = {
  isOrg?: boolean;
};

const ChatOk: FC<ChatOkProps> = ({isOrg}) => {
  const {t, i18n} = useTranslation(['chat', 'errors', 'messages', 'buttons']);
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();

  const [theme] = useState('light');
  const [createdActiveChannel, setCreatedActiveChannel] = useState<any>();
  const [isCreating, setIsCreating] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isMessagesFilterVisible, setIsMessagesFilterVisible] = useState(false);
  const [messagesFilterOptions, setMessagesFilterOptions] =
    useState<Array<MessagesFilterOption>>(defaultMessagesFilterOptions);

  const userChatId = useSelector(selectorGetChatId);
  const userOrgChatId = useSelector(selectorGetOrgChatId);
  const userOrgName = useSelector(selectorGetOrgName);
  const userToConnect = useSelector(selectorGetChatUserToConnect);
  const error = useSelector(selectorGetError);
  const chatClientConnectioState = useSelector(selectorGetUserConnectionState);

  const isUnread = useMemo(() => getIsUnread(messagesFilterOptions), [messagesFilterOptions]);
  const filters = useMemo(() => getFilters(userToConnect), [userToConnect]);

  useEffect(() => {
    if (userToConnect?.id) initChat();
  }, [userToConnect]);

  const lang = i18n.language.toLowerCase();

  const i18nInstance = new Streami18n({
    language: lang as TranslationLanguages,
    DateTimeParser: moment,
  });

  // @ts-ignore
  i18nInstance.registerTranslation('he', heTranslation);

  const messagesFilterContextValue = getMessagesContextValue({
    messagesFilterOptions,
    setMessagesFilterOptions,
    isMessagesFilterVisible,
    setIsMessagesFilterVisible,
  });

  const initChat = async () => {
    try {
      const initChatRequestInfo = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }) as unknown as InitChatRequestInfoType;

      const chatMembers: Array<ChatMember> | undefined = initChatRequestInfo?.members;

      if (chatMembers && chatMembers?.length) {
        if (isOrg && initChatRequestInfo && initChatRequestInfo.type) {
          try {
            if (!userOrgChatId) throw new Error(`${t('errors:sorry')} ${t('errors:cantFindOrgId')}`);

            if (initChatRequestInfo.type === 'all') {
              const listChatIds = chatMembers.map((member) => member.chatId);
              const channel = await chatClient?.channel(
                'messaging',
                chatMembers.length > 1
                  ? {
                      members: [...listChatIds, userOrgChatId],
                      name: initChatRequestInfo.title?.length
                        ? `${userOrgName} ${t('messages:to')} ${initChatRequestInfo.title}`
                        : `${userOrgName} ${t('messages:to')} ${t('messages:someGroup')}`,
                    }
                  : {
                      members: [chatMembers[0].chatId, userOrgChatId],
                    }
              );
              await channel?.watch();
              setCreatedActiveChannel(channel);
            }
            history.push({search: ''});
          } catch (error) {
            throw error;
          }
        }

        if (!isOrg && initChatRequestInfo?.members[0]?.chatName && userChatId) {
          try {
            const channel = await chatClient?.channel('messaging', {
              members: [...chatMembers.map((member) => member.chatId), userChatId],
              name: chatMembers.map((member) => member.chatName)[0],
            });
            await channel?.watch();
            setCreatedActiveChannel(channel);
          } catch (error) {
            throw error;
          }
        }
      }
      setIsLoading(false);
    } catch (error) {
      dispatch(setError(error?.response?.message || error.message, error.code));
    }
  };

  const retryClickHandler = () => dispatch(resetError);

  if (isLoading || !chatClientConnectioState) return <Loader loadProps={{stylePageCenter: true}} />;

  if (error.state && !isLoading)
    return (
      <ResponseFailure
        message={error.message}
        buttonTitle={t('buttons:button.ok')}
        buttonClickHandler={retryClickHandler}
      />
    );

  return (
    <div className={cx(styles.chatWrapper, isOrg && styles.org)}>
      <Chat client={chatClient} theme={`messaging ${theme}`} i18nInstance={i18nInstance}>
        <div id="mobile-channel-list">
          <MessagesFilterContextProvider value={messagesFilterContextValue}>
            <ChannelList
              filters={filters}
              sort={sort}
              options={options}
              channelRenderFilterFn={getChannelRenderFilterFn(isUnread)}
              setActiveChannelOnMount={false}
              List={(props) => (
                <MessagingChannelList
                  isOrg={isOrg}
                  channel={createdActiveChannel}
                  {...props}
                  onCreateChannel={() => setIsCreating(!isCreating)}
                />
              )}
              Preview={(props) => (
                <MessagingChannelPreview
                  {...props}
                  latestMessage={props?.latestMessage || ''}
                  setIsCreating={setIsCreating}
                  setCreatedActiveChannel={setCreatedActiveChannel}
                />
              )}
              EmptyStateIndicator={() => (
                <p>{t(isUnread ? 'messages:noUnreadMessages' : 'messages:haventOpenChats')}</p>
              )}
              Paginator={(props) => (
                <LoadMorePaginator
                  {...props}
                  LoadMoreButton={(props) => <LoadMoreButton {...props}>{t('chat:loadMore')}</LoadMoreButton>}
                />
              )}
            />
            {isMessagesFilterVisible && <MessagesFilter />}
          </MessagesFilterContextProvider>
        </div>
        <div className="messageListWrap">
          <Channel
            maxNumberOfFiles={10}
            multipleUploads
            EmojiPicker={EmojiPicker}
            TypingIndicator={CustomTypingIndicator}
            Avatar={UserChatAvatar}
            ThreadHeader={MessagingThread}
          >
            {isCreating && <CreateChannel onClose={() => setIsCreating(false)} />}
            <Window>
              <MessagingChannelHeader theme={theme} />
              <MessageList messageActions={['delete', 'edit', 'mute', 'react', 'reply']} Message={CustomMessage} />
              <MessageInput focus Input={MessagingInput} emojiSearchIndex={SearchIndex} />
            </Window>
            <Thread Message={CustomMessage} Input={MessagingInput} />;
          </Channel>
        </div>
      </Chat>
    </div>
  );
};

export default ChatOk;
