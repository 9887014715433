import {FC, useCallback, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';
//redux
import {Store} from 'redux/root';
import {
  selectorGetUserVolunteerId,
  selectorGetUserVolunteerStatus,
  selectorGetUserAddress,
} from 'redux/user-service/selector';
import {setError} from 'redux/error-service/action';
import {selectorGetError} from 'redux/error-service/selector';
import {joinToOpportunityCurrent} from 'redux/opportunities-service/action';
//assets
import {ReactComponent as CalendarSvg} from 'assets/image/calendar.svg';
import {ReactComponent as LocationSvg} from 'assets/image/location.svg';
// core
import {API} from 'core/API';
//urls
import {urls} from 'core/appUrls';
//functions
import {getTextDirection, isVolunteerCantMakeIt} from 'core/functions';
//types
import {JOIN_STATE} from 'core/types';
import {CANTMAKEIT, UPDATE_CURRENT_OPPORTUNITY} from 'redux/actions-types';
import {IOpportunityResponse, IUserResponse, SearchOpportunitiesRequest} from '@joc/api-gateway';
//helpers
import {
  getIsJoinButtonDisabled,
  getIsVolunteerSuspended,
  getLocationInfo,
  isCantMakeIt,
  getTimeInfo,
  getErrorMessage,
  getManualOppo,
  getJoinButtonTitle,
  getComponentsVisibleSettings,
  getIsVisibleAdditionalInfo,
} from './helpers';
//components
import VolunteerContact from './VolunteerContact';
import {FeedDetailsAttachments} from './FeedDetailsAttachments';
import PopupContainer from 'shared/components/PopupContainer';
import ResponseFailure from 'shared/components/ResponseFailure';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import {FeedDetailsAdditionalInfo} from './FeedDetailsAdditionalInfo';
import PopupJoinCongrats from '../Opportunities/OpportunitiesList/OpportunitiesItems/OpportunityItem/PopupJoinCongrats';
import PopupJoinFall from '../Opportunities/OpportunitiesList/OpportunitiesItems/OpportunityItem/PopupJoinFall';
//styles
import styles from './FeedDetailsView.module.scss';
import {ToggleOptOutStatusRequest} from '@joc/api-gateway/lib/api-client';

interface IFeedDetailsViewProps {
  isAdmin?: boolean;
  handleResetError?: () => void;
  userInfo?: IUserResponse | null;
  opportunityData: IOpportunityResponse;
}

const FeedDetailsView: FC<IFeedDetailsViewProps> = ({opportunityData, isAdmin, handleResetError, userInfo}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {t, i18n} = useTranslation(['errors', 'oppoViewVolunteer', 'buttons', 'popup', 'form']);
  const locationVirtualText = t('oppoViewVolunteer:locationVirtual');

  const textDirection = getTextDirection(i18n.language);

  const [errorMsg, setErrorMsg] = useState<string>('');
  const [joinState, setJoinState] = useState<JOIN_STATE>(JOIN_STATE.NONE);

  const error = useSelector((store: Store) => selectorGetError(store));
  const volunteerId = useSelector((store: Store) => selectorGetUserVolunteerId(store));
  const volunteerLocation = useSelector((store: Store) => selectorGetUserAddress(store));
  const volunteerStatus = useSelector((store: Store) => selectorGetUserVolunteerStatus(store));

  const handleResetResponseFailure = () => {
    handleResetError?.();
  };

  const handleJoinToOppo = useCallback(async () => {
    if (volunteerId) {
      try {
        dispatch(joinToOpportunityCurrent(opportunityData.id));
        setJoinState(JOIN_STATE.SUCCESS);
      } catch (error) {
        console.error(error);
        setJoinState(JOIN_STATE.FAIL);
        setErrorMsg(error.message);
      }
    } else history.push(urls.onboardingLoginVolunteer);
  }, [volunteerId, opportunityData.id]);

  const handleCantMakeIt = useCallback(async () => {
    try {
      if (volunteerId && opportunityData.id) {
        await API.toggleOpportunityVolunteerStatusCanMakeIt(
          opportunityData.id,
          ToggleOptOutStatusRequest.fromJS(undefined)
        );

        dispatch({type: CANTMAKEIT, payload: {id: opportunityData.id, volunteerId}});

        const updatedOpportunity = await API.searchOpportunitiesByVolunteer(
          undefined,
          volunteerLocation ? `${volunteerLocation.latitude},${volunteerLocation.longitude}` : undefined,
          SearchOpportunitiesRequest.fromJS({
            opportunityIds: [opportunityData.id],
          })
        );

        const payloadData = {data: updatedOpportunity, opportunityId: opportunityData.id};

        dispatch({type: UPDATE_CURRENT_OPPORTUNITY, payload: payloadData});

        setJoinState(JOIN_STATE.SUCCESS);
      }
    } catch (error) {
      dispatch(
        setError(
          error?.response?.data?.message || error?.response?.message || error.message,
          error?.response?.data?.code || error?.response?.code || error.code
        )
      );
    }
  }, [volunteerId, opportunityData.id]);

  const handleJoin = () =>
    isCantMakeIt(volunteerId, opportunityData.volunteers) ? handleCantMakeIt() : handleJoinToOppo();

  const isVolunteerSuspended = getIsVolunteerSuspended(volunteerStatus);

  const locationInfo = getLocationInfo(opportunityData.isVirtual, opportunityData.address, locationVirtualText);

  const timeInfo = getTimeInfo(
    opportunityData.duration,
    opportunityData.startDate,
    opportunityData.endDate,
    opportunityData.opportunityType
  );

  const isVisibleAdditionalInfo = useMemo(
    () => getIsVisibleAdditionalInfo(opportunityData, userInfo, volunteerId),
    [opportunityData, userInfo, volunteerId]
  );

  const isJoinButtonDisabled = useMemo(
    () =>
      getIsJoinButtonDisabled(
        isVolunteerSuspended,
        volunteerId,
        opportunityData.volunteers,
        opportunityData.opportunityActiveStatus?.status
      ),
    [isVolunteerSuspended, volunteerId, opportunityData.volunteers, opportunityData.opportunityActiveStatus?.status]
  );

  const joinButtonTitle = useMemo(
    () => getJoinButtonTitle(isVolunteerCantMakeIt, opportunityData, volunteerId),
    [opportunityData, volunteerId]
  );

  const errorMessages = useMemo(() => getErrorMessage(error), [error]);

  const isManualOppo = useMemo(() => getManualOppo(opportunityData), [opportunityData]);

  const isComponentsVisibleSetting = useMemo(
    () => getComponentsVisibleSettings(opportunityData, isManualOppo, joinState, isAdmin, volunteerId),
    [opportunityData, isManualOppo, joinState, isAdmin, volunteerId]
  );

  return (
    <div className={styles.switchView__item}>
      <div className={styles.wrapFeed__description}>
        <div className={styles.description__block}>
          <div className={styles.description__text}>
            <div className={styles.mainContent__title}>{opportunityData.opportunityName}</div>
            {isComponentsVisibleSetting.oppoTag && (
              <div className={styles.switchView__item__hashtag}>{`#${opportunityData.tag}`}</div>
            )}
            <span className={styles.mainContent__subtitle}>{`${t('form:opportunity.description')}:`}</span>
            <div
              dir={textDirection}
              className={styles.mainContent__description}
              dangerouslySetInnerHTML={{__html: opportunityData.opportunityDescription}}
            />
          </div>
          {isVisibleAdditionalInfo && (
            <FeedDetailsAdditionalInfo textDirection={textDirection} textInfo={opportunityData.additionalInformation} />
          )}
          {(isComponentsVisibleSetting.oppoImages && opportunityData.filePathes.length && (
            <FeedDetailsAttachments links={opportunityData.filePathes} />
          )) || <></>}
        </div>
        <div className={styles.mainContent__when}>
          <div className={styles.ico__item}>
            <CalendarSvg />
          </div>
          {timeInfo}
        </div>
        {isComponentsVisibleSetting.oppoLocation && (
          <div className={styles.mainContent__where}>
            <div className={styles.ico__item}>
              <LocationSvg />
            </div>
            {locationInfo}
          </div>
        )}
        {isComponentsVisibleSetting.oppoVolunteerContact && (
          <VolunteerContact
            lastName={opportunityData.user?.lastName}
            firstName={opportunityData.user?.firstName}
            isVolunteerSuspended={isVolunteerSuspended}
            chatId={opportunityData.organisation?.chatId}
            phoneNumber={opportunityData.user?.phoneNumber}
            organizationName={opportunityData.organisation?.organizationName}
          />
        )}
      </div>
      {isComponentsVisibleSetting.oppoButtonJoin && (
        <div className={styles.feed__mainFooterAction}>
          <ButtonDefault
            classList="lg primary"
            title={joinButtonTitle}
            clickHandler={handleJoin}
            disabled={isJoinButtonDisabled}
          />
        </div>
      )}
      {error.state && (
        <PopupContainer isDisableCloseButton isDisablePadding isContentCenter isDisableContentMarginTop>
          <ResponseFailure
            isReloginDisabled
            isVolunteersLimit
            message={errorMessages.popupMessage}
            buttonTitle={errorMessages.popupTitle}
            buttonClickHandler={handleResetResponseFailure}
          />
        </PopupContainer>
      )}
      {isComponentsVisibleSetting.oppoJoinSuccessfully && (
        <PopupJoinCongrats setJoinState={setJoinState} isApprovalRequired={opportunityData.isApprovalRequired} />
      )}
      {isComponentsVisibleSetting.oppoJoinUnsuccessfully && (
        <PopupJoinFall setJoinState={setJoinState} message={errorMsg} />
      )}
    </div>
  );
};

export default FeedDetailsView;
