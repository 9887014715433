import qs from 'qs';
import {ORGANIZATION_SETTINGS_TABS} from './interfaces';

export const getInitialTab = (search: string): ORGANIZATION_SETTINGS_TABS => {
  const initialTab = getCurrentTabFromQuery(search);

  return !!initialTab ? initialTab : ORGANIZATION_SETTINGS_TABS.Info;

  function getCurrentTabFromQuery(query: string): ORGANIZATION_SETTINGS_TABS | null {
    const parsedQuery = qs.parse(query, {ignoreQueryPrefix: true});

    return !!parsedQuery.tab ? (parsedQuery.tab as ORGANIZATION_SETTINGS_TABS) : null;
  }
};
