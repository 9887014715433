import {FC} from 'react';
//components
import WhiteContainer from 'shared/components/WhiteContainer';
import PopupContainer from 'shared/components/PopupContainer';
import TermsInfo from 'pages/Terms/TermsInfo';
import {useTranslation} from 'react-i18next';

type TermsPopupProps = {
  setIsShowPopup: () => void;
};

const TermsPopup: FC<TermsPopupProps> = ({setIsShowPopup}: TermsPopupProps) => {
  const {t} = useTranslation('terms');

  return (
    <PopupContainer setIsShowPopup={setIsShowPopup} isFixedStyle isDisablePadding isDisableContentMarginTop>
      <WhiteContainer title={t('title')}>
        <TermsInfo />
      </WhiteContainer>
    </PopupContainer>
  );
};

export default TermsPopup;
