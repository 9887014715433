import {createRef, FC, useEffect, useMemo, useState} from 'react';
import cx from 'classnames';
import {GradeResponse} from '@joc/api-gateway';
import FieldWrapper from '../../../../../shared/inputs/FieldWrapper';
import {FieldBaseProps} from '../../../../../core/types';
import i18next from '../../../../../components/translate';

import styles from '../../../../../shared/components/SelectDropdown/index.module.scss';

import {ReactComponent as BlueArrow} from 'assets/image/selects/blue-arrow.svg';
import {ReactComponent as WhiteArrow} from 'assets/image/selects/white-arrow.svg';
import {useClickOutside} from '../../../../../core/customHooks';
import {useSelector} from 'react-redux';
import {selectorGetOrgSchoolGrade} from '../../../../../redux/organization-service/selector';
import {useTranslation} from 'react-i18next';
import {useYearlyGoalsContext} from '../context';

type GradeProps = FieldBaseProps<{
  placeholder?: string;
  onChange?: (value: number) => void;
}>;

export const NO_GRADE = 'No Grade';

const Grades: FC<GradeProps> = ({
  placeholder = i18next.t('inputs:placeholders.selectGrade'),
  onChange,
  ...fieldBaseProps
}) => {
  const {t} = useTranslation(['common', 'settings', 'form']);
  const [schoolGrades, setSchoolGrades] = useState<GradeResponse[]>([]);

  const {field, form} = fieldBaseProps;
  const {setFieldValue, setFieldTouched} = form;

  const {isComponentVisible, componentRef, setIsComponentVisible} = useClickOutside({
    initialVisible: false,
    setFieldTouched,
    fieldName: field.name,
  });

  const schoolGradeInfo = useSelector(selectorGetOrgSchoolGrade);

  useEffect(() => {
    if (schoolGradeInfo?.length) {
      setSchoolGrades([GradeResponse.fromJS({id: 0, gradeName: NO_GRADE}), ...schoolGradeInfo]);
    }
  }, [schoolGradeInfo]);

  const gradeRef = useMemo(() => schoolGrades?.map(() => createRef<HTMLDivElement>()), [schoolGrades]);
  const title = useMemo(() => {
    if (field.value) {
      return `${t(`common:ordinal.${field.value}` as unknown as TemplateStringsArray)} ${t('form:schoolGrade')}`;
    }
    return '';
  }, [field.value]);

  const gradeClickHandler = (id: number): void => {
    if (onChange) onChange(id);
    // setFieldValue(field.name, id);
    setIsComponentVisible(false);
  };

  const titleClickHandler = () => {
    if (!field.value) return setIsComponentVisible(!isComponentVisible);
    const valueIndex = schoolGrades?.findIndex((i) => i.id.toString() === field.value.toString());
    const target = gradeRef && valueIndex && gradeRef[valueIndex].current;
    if (target)
      // set timeout cause in a first time the scroll doesn't work
      setTimeout(() => {
        target.scrollIntoView();
      }, 0);

    if (isComponentVisible) setFieldTouched(field.name, true);
    setIsComponentVisible(!isComponentVisible);
  };

  return (
    <FieldWrapper {...fieldBaseProps}>
      <div
        id={field.name}
        className={cx(styles.dropdown_select, styles.position, {
          [styles.dropdown_select__active]: isComponentVisible,
        })}
        ref={componentRef}
      >
        <div
          className={cx(styles.dropdown_select__title, {
            [styles.dropdown_select__title_selected]: !!field.value,
          })}
          onClick={titleClickHandler}
        >
          <span>{title || placeholder}</span>
          {!isComponentVisible ? (
            <BlueArrow className={styles.dropdown_select__title__vector} />
          ) : (
            <WhiteArrow className={styles.dropdown_select__title__vector} />
          )}
        </div>
        <div className={styles.dropdown_select__options}>
          {isComponentVisible &&
            schoolGrades.length &&
            schoolGrades.map((grade: GradeResponse) => (
              <div
                key={grade.id}
                className={styles.dropdown_select__options__item}
                onClick={() => gradeClickHandler(+grade.id)}
              >
                {Number(grade.id) === 0
                  ? t('settings:yearlyGoals.noGrade')
                  : `${t(`common:ordinal.${grade.id}` as unknown as TemplateStringsArray)} ${t('form:schoolGrade')}`}
              </div>
            ))}
        </div>
      </div>
    </FieldWrapper>
  );
};

export default Grades;
