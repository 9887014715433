// types
import {TOpportunityStatus} from './types';
import {
  OPPORTUNITY_VOLUNTEER_STATUS,
  GetVolunteerByIdResponse,
  IOpportunityResponse,
  OPPORTUNITY_STATUSES,
  OPPORTUNITY_TYPES,
} from '@joc/api-gateway';
// functions
import {checkIsShowAction} from 'core/functions';
// translate
import i18n from 'components/translate';
// interfaces
import {IClosedData, IOppoStatusVision} from './interfaces';

export const checkingClockedStatus = (volunteers?: GetVolunteerByIdResponse[], volunteerId?: string) => {
  return !!volunteers?.find(({id}) => id === volunteerId)?.clockInTime;
};

export const getVolunteerOpportunityStatus = (oppo: IOpportunityResponse, volunteerId?: string): string => {
  const {opportunityType, volunteers, status} = oppo;

  if (opportunityType === OPPORTUNITY_TYPES.MANUAL) {
    const volunteerStatus = volunteers?.find((volunteer) => volunteer.id === volunteerId)?.status || '';
    return volunteerStatus === OPPORTUNITY_VOLUNTEER_STATUS.CANT_MAKE_IT ? volunteerStatus : status;
  }

  return volunteers?.find((volunteer) => volunteer.id === volunteerId)?.status || '';
};

export const getStatusStyles = <T extends TOpportunityStatus>(
  status: T,
  styles: Record<string, string>
): string | undefined => {
  const statusMap: Record<TOpportunityStatus, string> = {
    [OPPORTUNITY_VOLUNTEER_STATUS.CONFIRM]: styles.confirm,
    [OPPORTUNITY_STATUSES.OPEN]: styles.confirm,
    [OPPORTUNITY_VOLUNTEER_STATUS.PENDING]: styles.pending,
    [OPPORTUNITY_STATUSES.CANCELED]: styles.rejected,
    [OPPORTUNITY_VOLUNTEER_STATUS.REJECT]: styles.rejected,
    [OPPORTUNITY_VOLUNTEER_STATUS.CANT_MAKE_IT]: styles.cantMakeIt,
  };

  return statusMap[status];
};

export const checkClockIconVisibility = (
  oppo: IOpportunityResponse,
  oppoStatus: TOpportunityStatus,
  isClosedData: IClosedData,
  isSuspended: boolean
) => {
  const {isClosed} = isClosedData;

  return oppoStatus === OPPORTUNITY_VOLUNTEER_STATUS.CONFIRM && !isClosed && !isSuspended;
};

export const getStatusText = (
  isCompletedTab: boolean,
  isClosedData: IClosedData,
  oppoStatusVision: IOppoStatusVision,
  volunteerOppoStatus: string
) => {
  const {text} = oppoStatusVision;
  const {isClosed, isClosedText} = isClosedData;

  const isVolunteerCantMakeIt = volunteerOppoStatus !== OPPORTUNITY_VOLUNTEER_STATUS.CANT_MAKE_IT;

  return isCompletedTab && isVolunteerCantMakeIt ? i18n.t('myChesed:statusCompleted') : isClosed ? isClosedText : text;
};

export const getClosedStatusData = (volunteerOppoStatus: string, oppo: IOpportunityResponse) => {
  const isClosed =
    oppo.status === OPPORTUNITY_STATUSES.CLOSED && volunteerOppoStatus !== OPPORTUNITY_VOLUNTEER_STATUS.CANT_MAKE_IT;

  return {
    isClosed,
    isClosedText: i18n.t('myChesed:closed'),
    isClosedIconVisible: isClosed,
  };
};
