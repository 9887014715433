// react
import {useSelector} from 'react-redux';
import {useViewport} from 'hooks';
import {useMemo} from 'react';
// redux
import {selectorGetUserCoins} from 'redux/user-service/selector';
// assets
import CoinsImage from 'assets/image/coins.png';
// styles
import styles from './Coins.module.scss';
import cx from 'classnames';

const CoinsData = () => {
  const coins = useSelector(selectorGetUserCoins);

  const {width, outerWidth} = useViewport();

  const isMobile = useMemo(() => width < 736 || outerWidth < 736, [width, outerWidth]);

  return (
    <div className={cx(styles.wrap, isMobile ? styles.mobileWrap : '')}>
      <p>
        <span className={cx(styles.coinsAmount, isMobile ? styles.mobileCoins : '')}>{coins}</span> coins
      </p>
      <img className={styles.image} src={CoinsImage} alt="coins" />
    </div>
  );
};

export default CoinsData;
