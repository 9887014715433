import {FC, useState, memo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
//chat
import {Attachment, logChatPromiseExecution, UserResponse} from 'stream-chat';
import {
  ChatAutoComplete,
  MessageInputProps,
  StreamMessage,
  UploadsPreview,
  useChannelActionContext,
  useChannelStateContext,
  useMessageInputContext,
} from 'stream-chat-react';
//redux
import {DefaultStreamChatGenerics} from '../CustomAvatar/types';
//images
import {EmojiIcon, LightningBoltSmall, SendIcon} from '../../../RandomImage';
//components
import {CustomFileDropzone} from '../FileDropzone/CustomFileDropzone';
import CustomEmojiPicker from '../EmojiPicker/CustomEmojiPicker';
//styles
import './MessagingInput.css';

const GiphyIcon = () => (
  <div className="giphy-icon__wrapper">
    <LightningBoltSmall />
    <p className="giphy-icon__text">GIPHY</p>
  </div>
);

const MessagingInput: FC<MessageInputProps> = (props) => {
  const {acceptedFiles, maxNumberOfFiles, multipleUploads} = useChannelStateContext<DefaultStreamChatGenerics>();

  const {sendMessage} = useChannelActionContext();
  const {insertText} = useMessageInputContext();

  const [giphyState, setGiphyState] = useState(false);

  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);

  const {t} = useTranslation('inputs');

  const emojiPickerRef = useRef(null);

  const overrideSubmitHandler = (message: {
    attachments: Attachment[];
    mentioned_users: UserResponse[];
    text: string;
    parent?: StreamMessage;
  }) => {
    let updatedMessage;

    if (message.attachments?.length && message.text?.startsWith('/giphy')) {
      const updatedText = message.text.replace('/giphy', '');
      updatedMessage = {...message, text: updatedText};
    }

    if (giphyState) {
      const updatedText = `/giphy ${message.text}`;
      updatedMessage = {...message, text: updatedText};
    }

    if (sendMessage) {
      const newMessage = updatedMessage || message;
      const parentMessage = newMessage.parent;

      const messageToSend = {
        ...newMessage,
        parent: parentMessage
          ? {
              ...parentMessage,
              created_at: parentMessage.created_at?.toString(),
              pinned_at: parentMessage.pinned_at?.toString(),
              updated_at: parentMessage.updated_at?.toString(),
            }
          : undefined,
      };

      const sendMessagePromise = sendMessage(messageToSend as any);
      logChatPromiseExecution(sendMessagePromise, 'send message');
    }

    setGiphyState(false);
  };

  const onEmojiSelect = (emoji: any) => insertText(emoji.native);

  const messageInput = useMessageInputContext();

  const changeEmojiPickerOpenStatus = () => setIsEmojiPickerOpen((prevStatus) => !prevStatus);

  return (
    <div className="str-chat__messaging-input">
      <div
        className="messaging-input__button emoji-button"
        role="button"
        aria-roledescription="button"
        onClick={changeEmojiPickerOpenStatus}
        ref={emojiPickerRef}
      >
        <EmojiIcon />
      </div>
      <CustomFileDropzone
        accept={acceptedFiles}
        handleFiles={messageInput.uploadNewFiles}
        multiple={multipleUploads}
        disabled={(maxNumberOfFiles !== undefined && messageInput.numberOfUploads >= maxNumberOfFiles) || giphyState}
      >
        <div className="messaging-input__input-wrapper">
          {giphyState && !messageInput.numberOfUploads && <GiphyIcon />}
          <UploadsPreview />
          <ChatAutoComplete rows={1} placeholder={t('placeholders.sendMessageOrDropFile')} />
        </div>
      </CustomFileDropzone>
      <div
        className="messaging-input__button"
        role="button"
        aria-roledescription="button"
        onClick={messageInput.handleSubmit}
      >
        <SendIcon />
      </div>
      <CustomEmojiPicker
        onSelectEmoji={onEmojiSelect}
        emojiPickerRef={emojiPickerRef}
        isOpen={isEmojiPickerOpen}
        changeIsOpenStatus={changeEmojiPickerOpenStatus}
      />
    </div>
  );
};

export default memo(MessagingInput);
