import {FC, useEffect, useState} from 'react';
import {connect, ConnectedProps, useDispatch, useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import cx from 'classnames';
//translation
import {useTranslation} from 'react-i18next';
//urls
import {urls} from 'core/appUrls';
//images
import GreetingSvg from 'assets/image/onboarding/Greeting.svg';
//redux
import {resetError} from 'redux/error-service/action';
import {getCurrentUser, userResetData} from 'redux/user-service/actions';
//components
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
//styles
import styles from './ChooseUserType.module.scss';
import {getOrgInfo} from '../../redux/organization-service/actions';
import {IRolesResponse, VOLUNTEER} from '@joc/api-gateway';
import {selectorGetUserData} from '../../redux/user-service/selector';
import useThunkDispatch from '../../hooks/useThunkDispatch';
import Loader from '../../shared/components/Loader';

const ChooseUserType: FC<ChooseUserTypeProps> = ({resetError, userResetData}: ChooseUserTypeProps) => {
  const {t, i18n} = useTranslation(['chooseAccType', 'common']);
  const thunkDispatch = useThunkDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const userData = useSelector(selectorGetUserData);

  useEffect(() => {
    const localStorageAccessToken = localStorage.getItem('accessToken');

    if (localStorageAccessToken) {
      setIsLoading(true);
      thunkDispatch(getCurrentUser()).finally(() => setIsLoading(false));
      const localStorageOrgId = localStorage.getItem('organisationId');
      if (localStorageOrgId) thunkDispatch(getOrgInfo(localStorageOrgId));
    } else {
      localStorage.clear();
      resetError();
      userResetData();
    }

    return () => {
      resetError();
      localStorage.setItem('i18nextLng', i18n.language);
    };
  }, []);

  useEffect(() => {
    if (userData) {
      setIsLoading(false);
      const volunteerId = localStorage.getItem('volunteerId');

      if (userData?.roles?.find((i: IRolesResponse) => i.roleName === VOLUNTEER.VOLUNTEER) && volunteerId) {
        history.push(urls.volHome);
        return;
      }

      if (
        userData?.roles?.find(
          (i: IRolesResponse) => i.roleName === VOLUNTEER.ADMIN || i.roleName === VOLUNTEER.MARKETER
        )
      ) {
        history.push(urls.orgDashboard);
        return;
      }
      if (userData?.roles?.find((i) => i.roleName === VOLUNTEER.COORDINATOR)) {
        history.push(urls.orgOpportunities);
      }
    }
  }, [userData]);

  return (
    <div className={styles.content}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={styles.content__greeting}>
            <img src={GreetingSvg} className={styles.greeting__image} alt="" />
            <span className={styles.greeting__title}>{t('chooseAccType:whoAmI')}</span>
          </div>
          <div className={styles.container}>
            <Link to={urls.onboardingLoginVolunteer}>
              <ButtonDefault classList={['secondary', 'lg']} title={t('chooseAccType:volunteer')} />
            </Link>
            <Link to={urls.onboardingLoginOrganization}>
              <ButtonDefault classList={['primary', 'lg']} title={t('chooseAccType:organization')} />
            </Link>
          </div>
          <div className={styles.linkBlock}>
            {process.env.REACT_APP_JOCP_ENV !== 'STAGE' && process.env.REACT_APP_JOCP_ENV}
            <Link to={urls.about} className={cx('link', styles.createAccount__link)}>
              {t('common:loginFooter.donate')}
            </Link>
            <Link to={urls.getInTouch} className={cx('link', styles.createAccount__link)}>
              {t('common:loginFooter.contact')}
            </Link>
            <Link to={urls.terms} className={cx('link', styles.createAccount__link)}>
              {t('common:loginFooter.terms')}
            </Link>
            <Link to={urls.policy} className={cx('link', styles.createAccount__link)}>
              {t('common:loginFooter.policy')}
            </Link>
            <Link to={urls.tariffPlans} className={cx('link', styles.createAccount__link)}>
              {t('common:loginFooter.pricing')}
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  resetError,
  userResetData,
};

const connector = connect(null, mapDispatchToProps);

type ChooseUserTypeProps = ConnectedProps<typeof connector>;

export default connector(ChooseUserType);
