// EN
// components
import tableHeadersEN from '../../../locales/en/components/tableHeaders.json';
import sectionsEN from '../../../locales/en/components/sections.json';
import commonEN from '../../../locales/en/components/common.json';
import popupEN from '../../../locales/en/components/popup.json';
import errorsEN from '../../../locales/en/components/errors.json';
import formEN from '../../../locales/en/components/form.json';
import messagesEN from '../../../locales/en/components/messages.json';
import reminderTapeEN from '../../../locales/en/components/reminderTape.json';
import headerEN from '../../../locales/en/components/header.json';
import tooltipsEN from '../../../locales/en/components/tooltips.json';
import buttonsEN from '../../../locales/en/components/buttons.json';
import inputsEN from '../../../locales/en/components/inputs.json';
import svgTextsEN from '../../../locales/en/components/svgTexts.json';
import validationEN from '../../../locales/en/validation.json';
// pages
// organizations pages
import chatEN from '../../../locales/en/pages/org/chat.json';
import chesedOpportunitiesEN from '../../../locales/en/pages/org/chesedOpportunities.json';
import dashboardEN from '../../../locales/en/pages/org/dashboard.json';
import editAccountEN from '../../../locales/en/pages/org/editAccount.json';
import editOrgEN from '../../../locales/en/pages/org/editOrg.json';
import getInTouchEN from '../../../locales/en/pages/org/getInTouch.json';
import inviteColleaguesEN from '../../../locales/en/pages/org/inviteColleagues.json';
import marketingEN from '../../../locales/en/pages/org/marketing.json';
import membersEN from '../../../locales/en/pages/org/members.json';
import statisticsEN from '../../../locales/en/pages/org/statistics.json';
import studentsEN from '../../../locales/en/pages/org/students.json';
import tariffPlansEN from '../../../locales/en/pages/org/tariffPlans.json';
import volunteersEN from '../../../locales/en/pages/org/volunteers.json';
import settingsEN from '../../../locales/en/pages/org/settings.json';

// volunteers pages
import myChesedEN from '../../../locales/en/pages/volunteer/myChesed.json';
import chesedEN from '../../../locales/en/pages/volunteer/chesed.json';
import diplomaEN from '../../../locales/en/pages/volunteer/diploma.json';
import oppoViewVolunteerEN from '../../../locales/en/pages/volunteer/oppoViewVolunteer.json';
// public pages
import loginEN from '../../../locales/en/pages/public/login.json';
import signUpUserEN from '../../../locales/en/pages/public/signUpUser.json';
import signUpOrgEN from '../../../locales/en/pages/public/signUpOrg.json';
import aboutEN from '../../../locales/en/pages/public/about.json';
import chooseAccTypeEN from '../../../locales/en/pages/public/chooseAccType.json';
import policyEN from '../../../locales/en/pages/public/policy.json';
import termsEN from '../../../locales/en/pages/public/terms.json';
import cookiesEN from '../../../locales/en/pages/public/cookies.json';

// HE
// components
import tableHeadersHE from '../../../locales/he/components/tableHeaders.json';
import sectionsHE from '../../../locales/he/components/sections.json';
import commonHE from '../../../locales/he/components/common.json';
import popupHE from '../../../locales/he/components/popup.json';
import errorsHE from '../../../locales/he/components/errors.json';
import formHE from '../../../locales/he/components/form.json';
import messagesHE from '../../../locales/he/components/messages.json';
import reminderTapeHE from '../../../locales/he/components/reminderTape.json';
import headerHE from '../../../locales/he/components/header.json';
import tooltipsHE from '../../../locales/he/components/tooltips.json';
import buttonsHE from '../../../locales/he/components/buttons.json';
import inputsHE from '../../../locales/he/components/inputs.json';
import svgTextsHE from '../../../locales/he/components/svgTexts.json';
// validation
import validationHE from '../../../locales/he/validation.json';
// pages
// organizations pages
import chatHE from '../../../locales/he/pages/org/chat.json';
import chesedOpportunitiesHE from '../../../locales/he/pages/org/chesedOpportunities.json';
import dashboardHE from '../../../locales/he/pages/org/dashboard.json';
import editAccountHE from '../../../locales/he/pages/org/editAccount.json';
import editOrgHE from '../../../locales/he/pages/org/editOrg.json';
import getInTouchHE from '../../../locales/he/pages/org/getInTouch.json';
import inviteColleaguesHE from '../../../locales/he/pages/org/inviteColleagues.json';
import marketingHE from '../../../locales/he/pages/org/marketing.json';
import membersHE from '../../../locales/he/pages/org/members.json';
import statisticsHE from '../../../locales/he/pages/org/statistics.json';
import studentsHE from '../../../locales/he/pages/org/students.json';
import tariffPlansHE from '../../../locales/he/pages/org/tariffPlans.json';
import settingsHE from '../../../locales/he/pages/org/settings.json';

// validation
import volunteersHE from '../../../locales/he/pages/org/volunteers.json';
// volunteers pages
import myChesedHE from '../../../locales/he/pages/volunteer/myChesed.json';
import chesedHE from '../../../locales/he/pages/volunteer/chesed.json';
import diplomaHE from '../../../locales/he/pages/volunteer/diploma.json';
import oppoViewVolunteerHE from '../../../locales/he/pages/volunteer/oppoViewVolunteer.json';
// public pages
import loginHE from '../../../locales/he/pages/public/login.json';
import signUpUserHE from '../../../locales/he/pages/public/signUpUser.json';
import signUpOrgHE from '../../../locales/he/pages/public/signUpOrg.json';
import aboutHE from '../../../locales/he/pages/public/about.json';
import chooseAccTypeHE from '../../../locales/he/pages/public/chooseAccType.json';
import policyHE from '../../../locales/he/pages/public/policy.json';
import termsHE from '../../../locales/he/pages/public/terms.json';
import cookiesHE from '../../../locales/he/pages/public/cookies.json';

export const resources = {
  en: {
    tableHeaders: tableHeadersEN,
    sections: sectionsEN,
    common: commonEN,
    popup: popupEN,
    errors: errorsEN,
    messages: messagesEN,
    form: formEN,
    reminderTape: reminderTapeEN,
    header: headerEN,
    tooltips: tooltipsEN,
    validation: validationEN,
    inputs: inputsEN,
    buttons: buttonsEN,
    svgTexts: svgTextsEN,
    // organizations pages
    chat: chatEN,
    chesedOpportunities: chesedOpportunitiesEN,
    dashboard: dashboardEN,
    editAccount: editAccountEN,
    editOrg: editOrgEN,
    getInTouch: getInTouchEN,
    inviteColleagues: inviteColleaguesEN,
    marketing: marketingEN,
    members: membersEN,
    statistics: statisticsEN,
    students: studentsEN,
    tariffPlans: tariffPlansEN,
    volunteers: volunteersEN,
    settings: settingsEN,
    // public pages
    login: loginEN,
    signUpUser: signUpUserEN,
    signUpOrg: signUpOrgEN,
    about: aboutEN,
    chooseAccType: chooseAccTypeEN,
    policy: policyEN,
    terms: termsEN,
    cookies: cookiesEN,
    // volunteers pages
    myChesed: myChesedEN,
    chesed: chesedEN,
    diploma: diplomaEN,
    oppoViewVolunteer: oppoViewVolunteerEN,
  },
  he: {
    tableHeaders: tableHeadersHE,
    sections: sectionsHE,
    common: commonHE,
    popup: popupHE,
    errors: errorsHE,
    messages: messagesHE,
    form: formHE,
    reminderTape: reminderTapeHE,
    header: headerHE,
    tooltips: tooltipsHE,
    validation: validationHE,
    inputs: inputsHE,
    buttons: buttonsHE,
    svgTexts: svgTextsHE,
    // organizations pages
    chat: chatHE,
    chesedOpportunities: chesedOpportunitiesHE,
    dashboard: dashboardHE,
    editAccount: editAccountHE,
    editOrg: editOrgHE,
    getInTouch: getInTouchHE,
    inviteColleagues: inviteColleaguesHE,
    marketing: marketingHE,
    members: membersHE,
    statistics: statisticsHE,
    students: studentsHE,
    tariffPlans: tariffPlansHE,
    volunteers: volunteersHE,
    settings: settingsHE,
    // public pages
    login: loginHE,
    signUpUser: signUpUserHE,
    signUpOrg: signUpOrgHE,
    about: aboutHE,
    chooseAccType: chooseAccTypeHE,
    policy: policyHE,
    terms: termsHE,
    cookies: cookiesHE,
    // volunteers pages
    myChesed: myChesedHE,
    chesed: chesedHE,
    diploma: diplomaHE,
    oppoViewVolunteer: oppoViewVolunteerHE,
  },
};

export const availableLanguages = Object.keys(resources);
