import {Dispatch, FC, SetStateAction, FocusEvent, useEffect, useState} from 'react';
import cx from 'classnames';
//formik
import {Field, Form, useFormikContext} from 'formik';
//translation
import {useTranslation} from 'react-i18next';
//type
import {IInviteUserRequest} from '@joc/api-gateway';
//components
import Input from 'shared/inputs/Input';
import Loader from 'shared/components/Loader';
import InputPhone from 'shared/components/FormInputs/InputPhone';
import RolesSelect from 'shared/components/SelectDropdown/RolesSelect';
import PopupConfirmation from 'shared/components/PopupConfirmation';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
//styles
import styles from './InviteColleaguesForm.module.scss';
import {emailBlurSignUpMainFormV} from 'core/functions/emailBlur';
import {UserType} from 'components/Organization/Volunteers/VolunteersPopups/InvitePopup/helpers';

type InviteColleaguesFormParentsProps = {
  isOrgSuspended: boolean;
  isLoading: boolean;
  isShowConfirmationChange: boolean;
  isEmptyObj: any;
  setIsShowConfirmationChange: Dispatch<SetStateAction<boolean>>;
  confirmationSubmitClickHandler: () => void;
  isShowPassword?: boolean;
  checkEmailExisting?: boolean;
  isSchool?: boolean;
};

const InviteColleaguesForm: FC<InviteColleaguesFormParentsProps> = ({
  isOrgSuspended,
  isLoading,
  isShowConfirmationChange,
  isEmptyObj,
  setIsShowConfirmationChange,
  isShowPassword,
  confirmationSubmitClickHandler,
  checkEmailExisting,
  isSchool,
}: InviteColleaguesFormParentsProps) => {
  const {touched} = useFormikContext<IInviteUserRequest>();
  const [errorEmail, setEmailError] = useState('');

  const onBlurEmailHandler = (email: string) =>
    checkEmailExisting && emailBlurSignUpMainFormV(email, setEmailError, UserType.Member);

  const {t} = useTranslation(['form', 'messages', 'inputs', 'buttons', 'inviteColleagues']);

  useEffect(() => {
    isEmptyObj(touched);
  }, [touched]);

  const errorMessageWithCurrentOrg = (message: string) => {
    return message.replace(
      '{*}',
      isSchool ? t('inviteColleagues:school').replace('.', '') : t('inviteColleagues:org').replace('.', '')
    );
  };

  return (
    <Form className={styles.form} title={isOrgSuspended ? t('messages:organizationSuspended') : ''}>
      <>
        <Field
          name="firstName"
          label={t('form:user.firstName')}
          placeholder={t('inputs:placeholders.firstName')}
          disabled={isOrgSuspended}
          component={Input}
        />
        <Field
          name="lastName"
          label={`${t('form:user.lastName')} `}
          placeholder={t('inputs:placeholders.lastName')}
          disabled={isOrgSuspended}
          component={Input}
        />
        <div className={cx(errorEmail.length && styles.email, styles.mb_16)}>
          <Field
            name="email"
            label={t('form:emailAddress')}
            placeholder={t('inputs:placeholders.enterEmail')}
            onBlur={({target}: FocusEvent<HTMLInputElement>) => onBlurEmailHandler(target?.value)}
            disabled={isOrgSuspended}
            parentClassName={styles.mb_0}
            component={Input}
          />
          {errorEmail && <span>{errorMessageWithCurrentOrg(errorEmail)}</span>}
        </div>
        <Field name="phoneNumber" label={t('form:phoneNumber')} disabled={isOrgSuspended} component={InputPhone} />
        <Field name="roleId" label={t('form:role')} disabled={isOrgSuspended} component={RolesSelect} />
        {isShowPassword && (
          <Field
            name="password"
            type="password"
            autoСomplete="new-password"
            label={`${t('form:user.password')} *`}
            placeholder={t('inputs:placeholders.password')}
            component={Input}
          />
        )}
        {isLoading ? (
          <Loader />
        ) : (
          <ButtonDefault
            submitType
            classList={['primary', 'lg']}
            title={t('buttons:button.confirm')}
            disabled={isOrgSuspended}
          />
        )}
        {isShowConfirmationChange && (
          <PopupConfirmation
            confirmClickHandler={() => {
              setIsShowConfirmationChange(false);
              confirmationSubmitClickHandler();
            }}
            cancelClickHandler={() => setIsShowConfirmationChange(false)}
            setIsShowPopup={setIsShowConfirmationChange}
          />
        )}
      </>
    </Form>
  );
};

export default InviteColleaguesForm;
