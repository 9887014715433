import {createContext, Dispatch, SetStateAction, useContext} from 'react';
import {ORGANIZATION_SETTINGS_TABS} from './interfaces';

type OrganizationSettingsContextValues = {
  activeTab: ORGANIZATION_SETTINGS_TABS;
  isUpdateSuccess: boolean;
  setIsUpdateSuccess: Dispatch<SetStateAction<boolean>>;
};

const OrganizationSettingsContext = createContext<OrganizationSettingsContextValues>(
  {} as OrganizationSettingsContextValues
);

export const OrganizationSettingsContextProvider = OrganizationSettingsContext.Provider;
export const useOrganizationSettingsContext = () => useContext(OrganizationSettingsContext);
