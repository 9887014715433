import {ElementType, FC, useMemo, useState} from 'react';
import qs from 'qs';
import {useHistory, useLocation} from 'react-router';

import {ORG_SETTINGS_TABS} from './constants';
import {OrganizationSettingsContextProvider} from './context';
import {getInitialTab} from './helpers';
import {ORGANIZATION_SETTINGS_TABS} from './interfaces';
import {queryDataConfig} from '../Opportunities/data';

// components
import {OrganizationSettingsTabs} from './OrganizationSettingsTabs';
import i18n from 'components/translate';
import Snackbar from './Snackbar';

// Tabs
import OrganizationInfo from './OrganizationInfo';
import LogYourChesed from './LogYourChesed';
import YearlyGoals from './YearlyGoals';

// styles
import styles from './OrganizationSetting.module.scss';
import {useTranslation} from 'react-i18next';

const OrganizationSettings: FC = () => {
  const {t} = useTranslation(['settings', 'messages']);

  const history = useHistory();
  const {search} = useLocation();
  const [activeTab, setActiveTab] = useState<ORGANIZATION_SETTINGS_TABS>(getInitialTab(search));
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);

  const setTabUrl = (tab: ORGANIZATION_SETTINGS_TABS) => {
    const queryData = qs.parse(search, queryDataConfig);
    const newQueryString = qs.stringify({...((queryData && queryData) || {}), tab});
    history.replace({search: newQueryString});
  };

  const handleActiveTab = (value: ORGANIZATION_SETTINGS_TABS) => {
    setActiveTab(value);
    setTabUrl(value);
  };

  const ActiveTabComponent = useMemo(() => {
    switch (activeTab) {
      case ORGANIZATION_SETTINGS_TABS.YearlyGoals:
        return <YearlyGoals />;
      case ORGANIZATION_SETTINGS_TABS.LogYourChesed:
        return <LogYourChesed />;
      default:
        return <OrganizationInfo />;
    }
  }, [activeTab]);

  return (
    <section className={styles.organisationSettings}>
      <div className={styles.organisationSettings__title}>{String(i18n.t('settings:organizationSettings'))}</div>
      <OrganizationSettingsContextProvider
        value={{
          activeTab,
          isUpdateSuccess,
          setIsUpdateSuccess,
        }}
      >
        <OrganizationSettingsTabs
          tabsConfig={ORG_SETTINGS_TABS}
          activeTab={activeTab}
          handleActiveTab={handleActiveTab}
          parentClassName={styles.organisationSettings__tabNav}
          parentTabItemClassName={styles.organisationSettings__tabItem}
        />
        <div className={styles.organisationSettings__content}>{ActiveTabComponent}</div>
        {(isUpdateSuccess && (
          <Snackbar
            isOpenSnackbar={isUpdateSuccess}
            setIsOpenSnackbar={setIsUpdateSuccess}
            hideDuration={3000}
            title={t('messages:success')}
            text={t('settings:successMessage')}
          />
        )) || <></>}
      </OrganizationSettingsContextProvider>
    </section>
  );
};

export default OrganizationSettings;
