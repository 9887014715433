import {ReactNode} from 'react';
import moment from 'moment';
// translations
import i18n from 'components/translate';
// interfaces
import {IGetJoinButtonTitle} from './interfaces';
//types
import {JOIN_STATE} from 'core/types';
import {ErrorPayload} from 'redux/error-service/types';
import {
  OPPORTUNITY_VOLUNTEER_STATUS,
  GetLocationAddressByIdResponse,
  GetVolunteerByIdResponse,
  IGetVolunteerByIdResponse,
  OPPORTUNITY_ACTIVE_STATUS,
  OPPORTUNITY_TYPES,
  VOLUNTEER_ACTIVE_STATUS,
  IOpportunityResponse,
  IUserResponse,
  OrganisationResponse,
} from '@joc/api-gateway';
//functions
import {
  convertTimeToHoursAndMinutes,
  getDurationDetailsWithDate,
  isMyOppo,
  isVolunteerCantMakeIt,
} from 'core/functions';

export const getIsVolunteerSuspended = (volunteerStatus: VOLUNTEER_ACTIVE_STATUS | undefined) =>
  volunteerStatus === VOLUNTEER_ACTIVE_STATUS.SUSPENDED;

export const getIsJoinButtonDisabled = (
  isVolunteerSuspended: boolean,
  volunteerId: string | undefined,
  volunteers: Array<GetVolunteerByIdResponse> | undefined,
  opportunityActiveStatus: OPPORTUNITY_ACTIVE_STATUS
): boolean =>
  isVolunteerSuspended ||
  (!!volunteerId && isMyOppo(volunteers, volunteerId) && !isVolunteerCantMakeIt(volunteers, volunteerId)) ||
  opportunityActiveStatus === OPPORTUNITY_ACTIVE_STATUS.SUSPENDED;

const getDateAndMonth = (startDate: Date): string => moment(startDate).format(process.env.REACT_APP_DATE_FORMAT);

const getSchedule = (startDate: Date, endDate: Date): string =>
  `${convertTimeToHoursAndMinutes(startDate)} - ${convertTimeToHoursAndMinutes(endDate)}`;

export const getTimeInfo = (
  duration: number,
  startDate: Date,
  endDate: Date,
  opportunityType: OPPORTUNITY_TYPES
): ReactNode => {
  const isManualOppo = opportunityType === OPPORTUNITY_TYPES.MANUAL;

  return isManualOppo ? (
    getDurationDetailsWithDate(duration, endDate, startDate)
  ) : (
    <>
      {getDateAndMonth(startDate)} <br />
      {getSchedule(startDate, endDate)}
    </>
  );
};

export const getLocationInfo = (
  isVirtual: boolean,
  address: GetLocationAddressByIdResponse | undefined,
  locationVirtualText: string
): string =>
  !isVirtual && !!address
    ? `${address.buildingNumber} ${address.streetName}, ${address.cityName}`
    : locationVirtualText;

export const isCantMakeIt = (
  volunteerId: string | undefined,
  volunteers: Array<GetVolunteerByIdResponse> | undefined
): boolean => !!volunteerId && isVolunteerCantMakeIt(volunteers, volunteerId);

export const getIsShowAdditionalInfo = (
  volunteers?: Array<IGetVolunteerByIdResponse>,
  volunteerId?: string,
  isApprovalRequired?: boolean,
  additionalInformation?: string
) => {
  if (additionalInformation) {
    const volunteer = volunteers?.find((volunteer) => volunteer.id === volunteerId);

    if (volunteer) {
      if (isApprovalRequired) {
        return volunteer.status === OPPORTUNITY_VOLUNTEER_STATUS.CONFIRM;
      }

      return true;
    }
  }

  return false;
};

export const getErrorMessage = (error: ErrorPayload) => {
  const errorMaxVolunteers = !!(error.state && error.message === 'Reached max volunteers count');
  return {
    errorMaxVolunteers,
    popupMessage: errorMaxVolunteers ? i18n.t('errors:errorMaxVolunteerCount') : error.message,
    popupTitle: errorMaxVolunteers ? i18n.t('buttons:button.back') : i18n.t('buttons:button.close'),
  };
};

export const getManualOppo = (oppo: IOpportunityResponse) => {
  const {MANUAL} = OPPORTUNITY_TYPES;
  const {opportunityType, volunteers, user} = oppo;

  return !!(opportunityType === MANUAL && volunteers?.find(({userId}) => userId === user?.id));
};

export const getJoinButtonTitle = (
  isVolunteerCantMakeIt: IGetJoinButtonTitle,
  oppo: IOpportunityResponse,
  volunteerId?: string
) => {
  const {volunteers} = oppo;
  return isVolunteerCantMakeIt(volunteers, volunteerId)
    ? i18n.t('buttons:button.rejoin')
    : i18n.t('buttons:button.join');
};

export const getComponentsVisibleSettings = (
  opportunityData: IOpportunityResponse,
  isManualOppo: boolean,
  joinState: JOIN_STATE,
  isAdmin?: boolean,
  volunteerId?: string
) => {
  const {FAIL, SUCCESS} = JOIN_STATE;
  const {tagId, tag, isVirtual, address, user, filePathes} = opportunityData;

  return {
    oppoButtonJoin: !!(!isManualOppo && !isAdmin),
    oppoJoinUnsuccessfully: joinState === FAIL,
    oppoJoinSuccessfully: joinState === SUCCESS,
    oppoTag: !!(tagId && tag),
    oppoLocation: !!(isVirtual || address),
    oppoVolunteerContact: !!(!isManualOppo && volunteerId && user),
    oppoImages: !!filePathes.length,
  };
};

const checkingIsUserMemberOrgOrSchool = (organisation?: OrganisationResponse, userInfo?: IUserResponse | null) => {
  return !!userInfo?.userOrganizations?.find(({organizationId}) => organizationId === organisation?.id);
};

const checkingIsUserMemberOppo = (volunteers?: GetVolunteerByIdResponse[], volunteerId?: string) => {
  const {CONFIRM} = OPPORTUNITY_VOLUNTEER_STATUS;

  return !!(volunteers?.find((volunteer) => volunteer.id === volunteerId)?.status === CONFIRM);
};

export const getIsVisibleAdditionalInfo = (
  oppo: IOpportunityResponse,
  userInfo?: IUserResponse | null,
  volunteerId?: string
) => {
  const {PRIVATE, PUBLIC} = OPPORTUNITY_TYPES;
  const {opportunityType, isApprovalRequired, additionalInformation, organisation, volunteers} = oppo;

  const isUserMemberOrgOrSchool = checkingIsUserMemberOrgOrSchool(organisation, userInfo);
  const isUserMemberOppo = checkingIsUserMemberOppo(volunteers, volunteerId);

  const case1 = opportunityType === PRIVATE && isApprovalRequired && isUserMemberOrgOrSchool && isUserMemberOppo;
  const case2 = opportunityType === PRIVATE && !isApprovalRequired && isUserMemberOrgOrSchool && isUserMemberOppo;
  const case3 = opportunityType === PUBLIC && isApprovalRequired && isUserMemberOppo;
  const case4 = opportunityType === PUBLIC && !isApprovalRequired && isUserMemberOppo;

  return !!additionalInformation && !!(case1 || case2 || case3 || case4);
};
