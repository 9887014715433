import {FC} from 'react';
//components
import WhiteContainer from 'shared/components/WhiteContainer';
import PopupContainer from 'shared/components/PopupContainer';
//styles
import CookiesInfo from 'pages/Cookies/CookiesInfo';

type CookiePopupProps = {
  setIsShowPopup: () => void;
};

const CookiePopup: FC<CookiePopupProps> = ({setIsShowPopup}: CookiePopupProps) => {
  return (
    <PopupContainer setIsShowPopup={setIsShowPopup} isFixedStyle isDisablePadding isDisableContentMarginTop>
      <WhiteContainer title="COOKIE POLICY" titleStyles={{textAlign: 'center'}}>
        <CookiesInfo />
      </WhiteContainer>
    </PopupContainer>
  );
};

export default CookiePopup;
