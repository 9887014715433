export enum ORGANIZATION_SETTINGS_TABS {
  LogYourChesed = 'LOG_YOUR_CHESED',
  YearlyGoals = 'YEARLY_GOALS',
  Info = 'INFO',
}

export interface IOrgSettingsTabsConfig {
  title: string;
  currentTab: ORGANIZATION_SETTINGS_TABS;
}
