import {FC, ReactNode, useEffect, useState} from 'react';
import {useLocation} from 'react-router';
import cx from 'classnames';
//redux
import {useSelector} from 'react-redux';
import {selectorGetUserData} from 'redux/user-service/selector';
//constants
import {urls} from 'core/appUrls';
//components
import HeaderVolunteer from 'components/Headers/HeaderVolunteer';
import ChangeDefaultPasswordPopup from './ChangeDefaultPasswordPopup';
import AddUserBirthdayPopup from './AddUserBirthdayPopup';
//styles
import styles from './MainPageLayoutV.module.scss';
import {useHistory} from 'react-router-dom';

type MainPageLayoutVPropsType = {
  children: ReactNode;
  parentClassName?: string;
};

const MainPageLayoutV: FC<MainPageLayoutVPropsType> = ({children, parentClassName}) => {
  const location = useLocation();
  const history = useHistory();

  const userData = useSelector(selectorGetUserData);

  const [isOpenChangePasswordPopup, setIsOpenChangePasswordPopup] = useState(false);
  const [isOpenAddBirthdayPopup, setIsOpenAddBirthdayPopup] = useState(false);

  const isFullWidth = location.pathname === urls.volInbox;
  const isEditAccountPage = location.pathname === urls.volEditAccount;

  // check for changes to the default password
  // and for setting user birthDate
  useEffect(() => {
    if (userData && !!!userData.passwordSettedAt) return setIsOpenChangePasswordPopup(true);
    if (userData && !!!userData.birthDate && !isOpenChangePasswordPopup && !isEditAccountPage)
      return setIsOpenAddBirthdayPopup(true);
  }, [userData, location, isOpenChangePasswordPopup]);

  useEffect(() => {
    const volunteerId = localStorage.getItem('volunteerId');

    if (!volunteerId) {
      history.push(urls.onboardingChooseUserType);
    }
  }, [userData]);

  return (
    <div className={styles.main}>
      <HeaderVolunteer />
      <div className={cx(styles.main__content, parentClassName && parentClassName, isFullWidth && styles.fullWidth)}>
        {children}
      </div>

      {isOpenChangePasswordPopup && <ChangeDefaultPasswordPopup handleOpenPopup={setIsOpenChangePasswordPopup} />}
      {isOpenAddBirthdayPopup && <AddUserBirthdayPopup handleOpenPopup={setIsOpenAddBirthdayPopup} />}
    </div>
  );
};

export default MainPageLayoutV;
