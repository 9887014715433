import {FC, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';
//redux
import {changeStatusVolunteers, detachVolunteers} from 'redux/opportunities-service/action';
// other packages
import qs from 'qs';
//urls
import {urls} from 'core/appUrls';
//types
import {
  OPPORTUNITY_VOLUNTEER_STATUS,
  StatusOpportunityVolunteersRequestStatus,
  OPPORTUNITY_STATUSES,
  OPPORTUNITY_TYPES,
} from '@joc/api-gateway';
//components
import ButtonRejectCircle from 'shared/components/Buttons/ButtonActionCircle/ButtonRejectCircle';
import ButtonConfirmCircle from 'shared/components/Buttons/ButtonActionCircle/ButtonConfirmCircle';
import PopupConfirmation from 'shared/components/PopupConfirmation';
import Button from '@material-ui/core/Button';
// icons
import {ReactComponent as ChatIcon} from 'assets/icons/chat.svg';
//styles
import styles from './PossibleVolunteersActionsCell.module.scss';

type PossibleVolunteersActionsCellProps = {
  opportunityId: number;
  opportunityStatus: OPPORTUNITY_STATUSES;
  opportunityType: OPPORTUNITY_TYPES;
  volunteerId: string;
  volunteerStatus: OPPORTUNITY_VOLUNTEER_STATUS;
  volunteerChatId: string | undefined;
  volunteerFullName: string;
  isOrgSuspended?: boolean;
  isApprovalRequired?: boolean;
};

// TODO: fix props drilling
const PossibleVolunteersActionsCell: FC<PossibleVolunteersActionsCellProps> = ({
  volunteerId,
  opportunityId,
  opportunityStatus,
  opportunityType,
  volunteerStatus,
  volunteerChatId,
  volunteerFullName,
  isOrgSuspended,
  isApprovalRequired,
}: PossibleVolunteersActionsCellProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {t} = useTranslation(['buttons', 'messages']);

  const [isShowPopup, setIsShowPopup] = useState(false);

  const confirmClickHandler = () =>
    dispatch(changeStatusVolunteers(opportunityId, [+volunteerId], StatusOpportunityVolunteersRequestStatus.CONFIRM));

  const rejectClickHandler = () =>
    dispatch(changeStatusVolunteers(opportunityId, [+volunteerId], StatusOpportunityVolunteersRequestStatus.REJECT));

  const handleCreateChat = () => {
    const queryString = qs.stringify({
      members: [{chatId: volunteerChatId, chatName: volunteerFullName}],
      type: 'all',
    });
    history.push(`${urls.orgInbox}?${queryString}`);
  };

  return (
    <div className={styles.main}>
      {opportunityStatus === OPPORTUNITY_STATUSES.OPEN &&
        opportunityType !== OPPORTUNITY_TYPES.MANUAL &&
        isApprovalRequired && (
          <div className={styles.main__actions}>
            <ButtonConfirmCircle
              clickHandler={confirmClickHandler}
              disabled={
                volunteerStatus === OPPORTUNITY_VOLUNTEER_STATUS.CONFIRM ||
                volunteerStatus === OPPORTUNITY_VOLUNTEER_STATUS.CANT_MAKE_IT ||
                isOrgSuspended
              }
              title={t(!isOrgSuspended ? 'buttons:button.confirm' : 'messages:organizationSuspended')}
            />
            <ButtonRejectCircle
              clickHandler={rejectClickHandler}
              disabled={
                volunteerStatus === OPPORTUNITY_VOLUNTEER_STATUS.REJECT ||
                volunteerStatus === OPPORTUNITY_VOLUNTEER_STATUS.CANT_MAKE_IT ||
                isOrgSuspended
              }
              title={t(!isOrgSuspended ? 'buttons:button.reject' : 'messages:organizationSuspended')}
            />
          </div>
        )}
      {opportunityType !== OPPORTUNITY_TYPES.MANUAL && opportunityStatus === OPPORTUNITY_STATUSES.CLOSED && (
        <div className={styles.main__actions}>
          <ButtonRejectCircle
            clickHandler={() => setIsShowPopup(true)}
            title={t(!isOrgSuspended ? 'buttons:button.remove' : 'messages:organizationSuspended')}
          />
        </div>
      )}
      <div className={styles.main__contact} />
      {!!volunteerChatId && (
        <Button
          title=""
          className={styles.outlined}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleCreateChat}
        >
          <ChatIcon />
        </Button>
      )}
      {isShowPopup && (
        <PopupConfirmation
          confirmClickHandler={() => {
            dispatch(detachVolunteers(opportunityId, [+volunteerId]));
            setIsShowPopup(false);
          }}
          cancelClickHandler={() => setIsShowPopup(false)}
          setIsShowPopup={setIsShowPopup}
          text={t('messages:areYouSureRemoveVolunteer')}
        />
      )}
    </div>
  );
};

export default PossibleVolunteersActionsCell;
