import {FC} from 'react';
import TabNav from '../../../../shared/components/TabNav';
import TabNavItem from '../../../../shared/components/TabNav/TabNavItem';
import {IOrgSettingsTabsConfig, ORGANIZATION_SETTINGS_TABS} from '../interfaces';
import cx from 'classnames';

interface IOrganizationSettingsTabsProps {
  tabsConfig: IOrgSettingsTabsConfig[];
  activeTab: ORGANIZATION_SETTINGS_TABS;
  handleActiveTab: (value: ORGANIZATION_SETTINGS_TABS) => void;
  parentClassName?: string;
  parentTabItemClassName?: string;
}

export const OrganizationSettingsTabs: FC<IOrganizationSettingsTabsProps> = ({
  tabsConfig,
  handleActiveTab,
  activeTab,
  parentClassName,
  parentTabItemClassName,
}) => {
  return (
    <TabNav
      className={cx('oppoNav', {
        [parentClassName as string]: !!parentClassName,
      })}
    >
      {tabsConfig?.map(({title, currentTab}) => {
        return (
          <TabNavItem
            key={title}
            title={title}
            activeTab={activeTab === currentTab}
            clickHandler={() => handleActiveTab(currentTab)}
            parentClassName={parentTabItemClassName}
          />
        );
      })}
    </TabNav>
  );
};
