import {ReactComponent as ArrowSvg} from 'assets/icons/arrow.svg';
import styles from './ActiveChallenge.module.scss';
import ManualOppoPhotoTemplate from 'assets/image/default-manual-oppo.png';
import Confetti from 'assets/image/confetti.png';
import {FC, useMemo} from 'react';
import {IChallengeResponse} from '@joc/api-gateway';
import {getUserPhotoSrc} from '../../../../core/functions';

export const ActiveChallenge: FC<{challenge: IChallengeResponse; onChallengeOpen: () => void}> = ({
  challenge,
  onChallengeOpen,
}) => {
  const parsedImagePath = useMemo(() => getUserPhotoSrc(challenge?.imagePath, undefined), [challenge?.imagePath]);

  const handleRedirectClick = () => {
    onChallengeOpen();
  };

  return (
    <div
      className={styles.wrap}
      style={{
        backgroundImage: `url(${Confetti}), linear-gradient(294.14deg, #ffb64a 8.03%, #ffd34a 85.42%, #ffe24a 105.62%)`,
      }}
    >
      <div className={styles.dataBlock}>
        <span className={styles.challengeTitle}>{challenge?.name}</span>
        <span className={styles.challengeDescription}>{challenge?.title}</span>
        <div className={styles.navButton} onClick={handleRedirectClick}>
          <span className={styles.navText}>Explore</span>
          <ArrowSvg className={styles.navArrow} />
        </div>
      </div>

      <img className={styles.imgBlock} src={parsedImagePath} />
    </div>
  );
};
