import {Dispatch, FC, SetStateAction, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
//redux
import {changeStatusVolunteers} from 'redux/opportunities-service/action';
import {selectorGetOrgId, selectorGetSchoolId} from 'redux/organization-service/selector';
//types
import {
  OPPORTUNITY_VOLUNTEER_STATUS,
  IGetVolunteerByIdResponse,
  IOpportunityResponse,
  IOrganisationResponse,
  OPPORTUNITY_STATUSES,
  OPPORTUNITY_TYPES,
  StatusOpportunityVolunteersRequestStatus,
} from '@joc/api-gateway';
//components
import {VolunteerLimit} from 'shared/components/VolunteerLimit/VolunteerLimit';
import PopupVolunteers from 'components/Organization/Opportunities/OpportunityPopups/PopupVolunteers';
import VolunteerBadges from 'shared/components/Table/Badges/VolunteerBadges';
import ButtonOutlined from 'shared/components/Buttons/ButtonOutlined';
import ButtonConfirmCircle from '../../Buttons/ButtonActionCircle/ButtonConfirmCircle';
import ButtonRejectCircle from '../../Buttons/ButtonActionCircle/ButtonRejectCircle';
import ImageWithPopup from 'shared/components/ImageWithPopup';
//styles

import styles from './VolunteerRenderer.module.scss';

type SingleVolunteerProps = {
  photo: string;
  volunteerName: string;
  isOptionsDisable?: boolean;
  isApprovalRequired?: boolean;
  volunteerId: string;
  opportunityId: number;
  setIsPreviewOpen?: Dispatch<SetStateAction<boolean>>;
  opportunityName: string;
  volunteersArray: Array<IGetVolunteerByIdResponse>;
  volunteerStatus: OPPORTUNITY_VOLUNTEER_STATUS;
  opportunityStatus: OPPORTUNITY_STATUSES;
  volunteerCreatedDate: Date;
  volunteerOrganizations: Array<IOrganisationResponse> | undefined;
  opportunityType: OPPORTUNITY_TYPES;
  isViewVolunteersDisable?: boolean;
  isShowVolunteerLimit?: boolean;
  opportunity: IOpportunityResponse;
};

const SingleVolunteer: FC<SingleVolunteerProps> = ({
  opportunity,
  photo,
  volunteerName,
  isOptionsDisable,
  isApprovalRequired,
  opportunityId,
  setIsPreviewOpen,
  opportunityName,
  volunteersArray,
  opportunityStatus,
  volunteerCreatedDate,
  volunteerOrganizations,
  opportunityType,
  isViewVolunteersDisable,
  isShowVolunteerLimit,
}: SingleVolunteerProps) => {
  const dispatch = useDispatch();
  const orgId = useSelector(selectorGetOrgId);
  const schoolId = useSelector(selectorGetSchoolId);

  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);

  useEffect(() => {
    if (setIsPreviewOpen) if (setIsPreviewOpen) setIsPreviewOpen(isShowPopup);
  }, [isShowPopup]);

  const {t, i18n} = useTranslation(['buttons', 'popup']);

  const isVisibleVolunteersLimit = useMemo(
    () => isShowVolunteerLimit && Boolean(opportunity.volunteersMaxCount),
    [opportunity, isShowVolunteerLimit]
  );

  const isVisibleMemberBadge = useMemo(
    () => !!volunteerOrganizations?.find((i) => +i.id === orgId)?.id,
    [volunteerOrganizations, orgId]
  );

  return (
    <div className={styles.volunteer}>
      <div className={cx(styles.volunteer__info, !isOptionsDisable && styles.volunteer__info__mb)}>
        <ImageWithPopup
          classNames={styles.volunteer__info__photo}
          imagePath={photo}
          popupTitle={t(`popup:profilePicture.${schoolId ? 'student' : 'volunteer'}`)}
        />
        <div className={styles.volunteer__info_name}>
          <VolunteerBadges
            createDate={volunteerCreatedDate}
            isShowMemberBadge={isVisibleMemberBadge}
            language={i18n.language}
          />
          <span>{volunteerName}</span>
        </div>
      </div>

      {isVisibleVolunteersLimit && (
        <div className={styles.volunteersLimit}>
          <VolunteerLimit
            maxVolunteers={opportunity.volunteersMaxCount || 0}
            currentVolunteers={opportunity.volunteersCount || 0}
          />
        </div>
      )}
      {!isViewVolunteersDisable && (
        <ButtonOutlined
          title={t('buttons:button.viewMore')}
          clickHandler={() => setIsShowPopup(true)}
          disabled={isViewVolunteersDisable}
        />
      )}
      {isShowPopup && (
        <PopupVolunteers
          opportunity={opportunity}
          opportunityName={opportunityName}
          setIsShowPopup={setIsShowPopup}
          opportunityId={opportunityId}
          volunteersArray={volunteersArray}
          opportunityStatus={opportunityStatus}
          opportunityType={opportunityType}
          isApprovalRequired={isApprovalRequired}
        />
      )}
    </div>
  );
};

export default SingleVolunteer;
