import {ChangeEvent, FC, useEffect, useState} from 'react';
import Dropzone from 'react-dropzone';
import {useTranslation} from 'react-i18next';
//translate
import i18n from 'components/translate';
//API
import {API} from 'core/API';
//functions
import {convertFileSizeToMb, getImageTypeErrorText, validateFileType} from 'core/functions';
//constants
import {defaultPhotoTypesWithoutImage, IMAGE_SIZE_MAX} from 'core/constants';
//types
import {FieldBaseProps} from 'core/types';
//components
import FieldWrapper from 'shared/inputs/FieldWrapper';
import InputErrorHint from 'shared/components/InputErrorHint';
import Loader from 'shared/components/Loader';
import InputPreviewImage from './InputPreviewImage';
//styles
import styles from './index.module.scss';

type InputLogoProps = FieldBaseProps;

const InputPhoto: FC<InputLogoProps> = (fieldBaseProps) => {
  const {field, form, disabled} = fieldBaseProps;
  const {setFieldValue, setFieldTouched} = form;

  const [photoName, setPhotoName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [customError, setCustomError] = useState('');
  const [errorDropInput, setErrorDropInput] = useState<boolean>(false);

  const {t} = useTranslation(['form', 'errors']);

  const validationT = i18n.getFixedT(null, 'validation');

  const changeHandler = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    event.preventDefault();
    if (event.target && event.target.files && event.target.files[0]) {
      const isValidType = validateFileType(event.target.files[0]);

      if (!isValidType) {
        const formatError = `${validationT('upload.invalidFormat')}`;
        setFieldTouched(field.name, true);
        setCustomError(getImageTypeErrorText(formatError, defaultPhotoTypesWithoutImage));
        return;
      }

      setCustomError('');
      const fileName = event.target.files[0].name;
      const photo = event.target.files[0];
      const fileSize = event.target.files[0].size;

      setFieldTouched(field.name, true);
      const imageSize = convertFileSizeToMb(fileSize);

      if (imageSize >= IMAGE_SIZE_MAX) {
        const sizeError = validationT('upload.tooLarge');
        setCustomError(`${sizeError} 5 MB`);
        return;
      }

      try {
        setIsLoading(true);
        const response = await API.uploadPhoto({data: photo, fileName});
        response.fileName.length && setPhotoName(response.fileName);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setCustomError(error?.response?.message || error.message);
      }
    }
  };

  const dropHandler = async (acceptedFiles: Array<File>): Promise<void> => {
    if (acceptedFiles?.length) {
      setCustomError('');
      const photo = acceptedFiles[0];
      const fileName = acceptedFiles[0].name;
      const fileSize = acceptedFiles[0].size;

      setFieldTouched(field.name, true);

      if (convertFileSizeToMb(fileSize) >= IMAGE_SIZE_MAX) {
        const sizeError = validationT('upload.tooLarge');
        setCustomError(`${sizeError} 5 MB`);
        return;
      }

      try {
        setIsLoading(true);
        const response = await API.uploadPhoto({data: photo, fileName});
        response.fileName.length && setPhotoName(response.fileName);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setCustomError(error?.response?.message || error.message);
      }
    }
  };

  useEffect(() => {
    const photoName = localStorage.getItem('userPhoto');
    !!photoName && setFieldValue(field.name, photoName);
    return () => localStorage.removeItem('userPhoto');
  }, []);

  useEffect(() => {
    if (photoName?.length) {
      localStorage.setItem('userPhoto', photoName);
      setFieldValue(field.name, photoName);
    }
  }, [photoName]);

  const fieldError = form.errors[field.name] as string;
  const fieldTouched = form.touched[field.name] as boolean;

  if (isLoading)
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );

  return (
    <FieldWrapper {...fieldBaseProps}>
      <div className={styles.input_file}>
        <label className={styles.upload__button} htmlFor={field.name}>
          <Dropzone
            accept="image/*"
            multiple={false}
            onDrop={(acceptedFiles) => dropHandler(acceptedFiles)}
            disabled={disabled}
          >
            {({getRootProps, isDragReject}) => {
              if (isDragReject) {
                setErrorDropInput(isDragReject);
              }
              if (field.value)
                return (
                  <InputPreviewImage
                    value={field.value}
                    getRootProps={getRootProps}
                    isDragReject={isDragReject}
                    fieldError={fieldError}
                  />
                );
              return <div className={styles.upload} {...getRootProps()} />;
            }}
          </Dropzone>
        </label>
        <div className={styles.input_wrapper}>
          <input
            id={field.name}
            type="file"
            accept="image/*"
            onChange={(event) => changeHandler(event)}
            disabled={disabled}
          />
          <div>
            {t('form:fileInput.dragAndDropYourFileOr')}
            <label className={styles.upload__button} htmlFor={field.name}>
              {t('form:fileInput.browseFile')}
            </label>
          </div>
        </div>
        {errorDropInput ? (
          <div className={styles.upload__error}>
            <InputErrorHint errorText={t('errors:shouldDropFile')} />
          </div>
        ) : fieldTouched && fieldError && !customError ? (
          <div className={styles.upload__error}>
            <InputErrorHint errorText={fieldError} />
          </div>
        ) : (
          fieldTouched &&
          customError && (
            <div className={styles.upload__error}>
              <InputErrorHint errorText={customError} />
            </div>
          )
        )}
      </div>
    </FieldWrapper>
  );
};

export default InputPhoto;
