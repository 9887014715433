import {createContext, Dispatch, SetStateAction, useContext} from 'react';
import {IYearlyGoalRequest} from '@joc/api-gateway/lib/api-client';

type YearlyGoalsContextValues = {
  setGradesSettings: Dispatch<SetStateAction<IYearlyGoalRequest[]>>;
  gradesSettings: IYearlyGoalRequest[];
};

export const YearlyGoalsContext = createContext({} as YearlyGoalsContextValues);

export const YearlyGoalsContextProvider = YearlyGoalsContext.Provider;

export const useYearlyGoalsContext = () => useContext(YearlyGoalsContext);
