import {GET_OPEN_CHALLENGE} from '../actions-types';
import {ChallengesActionTypes, IChallengesReducer} from './types';

const initState: IChallengesReducer = {
  challenge: null,
};

const challengesReducer = (state = initState, action: ChallengesActionTypes): IChallengesReducer => {
  const {type, payload}: ChallengesActionTypes = action;

  switch (type) {
    case GET_OPEN_CHALLENGE: {
      return {
        ...state,
        challenge: payload,
      };
    }

    default:
      return state;
  }
};

export default challengesReducer;
