// react
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
// redux
import {selectorGetUserImage} from 'redux/user-service/selector';
//components
import WhiteContainer from 'shared/components/WhiteContainer';
import ImageWithPopup from 'shared/components/ImageWithPopup';
import CoinsData from './Coins';
import ProgressData from './Progress';
// styles
import styles from './Home.module.scss';
import cx from 'classnames';
import {useViewport} from 'hooks';
import {useEffect, useMemo, useState} from 'react';
import {ActiveChallenge} from './ActiveChallenge';
import {setUserPhotoSrc} from '../../../core/functions';
import ChallengesPreview from './ActiveChallenge/ChallengesPreview';
import {getOpenChallenge} from '../../../redux/challenges-service/actions';
import {Store} from '../../../redux/root';

const HomePage = () => {
  const {t} = useTranslation('header');
  const dispatch = useDispatch();

  const userImage = useSelector(selectorGetUserImage);
  const challenge = useSelector((store: Store) => store.challengeRedux.challenge);

  const [isChallengePreview, setIsChallengePreview] = useState(false);

  const {width, outerWidth} = useViewport();
  const isMobile = useMemo(() => width < 736 || outerWidth < 736, [width, outerWidth]);

  useEffect(() => {
    if (!challenge) {
      dispatch(getOpenChallenge());
    }
  }, []);

  return (
    <WhiteContainer parentClassName={styles.wrap} title={isChallengePreview ? '' : t('nav.link0')}>
      {isChallengePreview ? (
        <section className={cx(styles.challengesPreview, isMobile ? styles.mobileUserData : '')}>
          <ChallengesPreview previewData={challenge} onClose={() => setIsChallengePreview(false)} isMobile={isMobile} />
        </section>
      ) : (
        <div
          className={cx(styles.mainBlock, isMobile ? styles.mobileBlock : '')}
          style={{maxWidth: `${outerWidth - 72}px`}}
        >
          <div className={styles.backgroundWrap}>
            <div className={cx(styles.userInfoData, isMobile ? styles.mobileUserData : '')}>
              <img
                className={cx(styles.avatar, isMobile ? styles.mobileAvatar : '')}
                src={setUserPhotoSrc(userImage)}
                alt="avatar"
              />

              <div className={styles.statsData}>
                <CoinsData />

                <hr />

                <ProgressData />
              </div>
            </div>
          </div>
          {challenge ? (
            <div className={cx(styles.challengesData, isMobile ? styles.mobileUserData : '')}>
              <ActiveChallenge challenge={challenge} onChallengeOpen={() => setIsChallengePreview(true)} />
            </div>
          ) : null}
        </div>
      )}
    </WhiteContainer>
  );
};

export default HomePage;
