import {FC} from 'react';
//translation
import {useTranslation} from 'react-i18next';
//components
import ArrowBackLink from 'shared/components/ArrowBackLink';
import WhiteContainer from 'shared/components/WhiteContainer';
// styles
import styles from './Cookies.module.scss';
import CookiesInfo from './CookiesInfo';

const Cookies: FC = () => {
  const {t} = useTranslation('cookies');
  return (
    <>
      <ArrowBackLink parentClassName={styles.nav} />
      <WhiteContainer title={t('title')}>
        <CookiesInfo />
      </WhiteContainer>
    </>
  );
};

export default Cookies;
