import {OPPORTUNITY_VOLUNTEER_STATUS, OPPORTUNITY_STATUSES, OPPORTUNITY_TYPES} from '@joc/api-gateway';

export enum VOLUNTEER_TABS {
  ALL = 'ALL',
  COMPLETED = 'COMPLETED',
  UPCOMING = 'UPCOMING',
  REJECTED = 'REJECTED',
  CANT_MAKE_IT = 'CANT_MAKE_IT',
  PAST = 'PAST',
  MANUAL = 'MANUAL',
}

export const DEFAULT_OPPO_TYPE_LIST = [OPPORTUNITY_TYPES.PRIVATE, OPPORTUNITY_TYPES.PUBLIC, OPPORTUNITY_TYPES.MANUAL];

export const AllTabQueryPreset = {
  tabName: VOLUNTEER_TABS.ALL,
  status: [
    OPPORTUNITY_STATUSES.OPEN,
    OPPORTUNITY_STATUSES.PENDING,
    OPPORTUNITY_STATUSES.CANCELED,
    OPPORTUNITY_STATUSES.CLOSED,
  ],
  opportunityTypesList: DEFAULT_OPPO_TYPE_LIST,
};

export const CompletedTabQueryPreset = {
  tabName: VOLUNTEER_TABS.COMPLETED,
  status: [OPPORTUNITY_STATUSES.CLOSED],
  volunteerStatus: [OPPORTUNITY_VOLUNTEER_STATUS.CONFIRM],
  opportunityTypesList: DEFAULT_OPPO_TYPE_LIST,
};
export const UpcomingTabQueryPreset = {
  tabName: VOLUNTEER_TABS.UPCOMING,
  status: [OPPORTUNITY_STATUSES.OPEN, OPPORTUNITY_STATUSES.PENDING],
  opportunityTypesList: DEFAULT_OPPO_TYPE_LIST,
  volunteerStatus: [OPPORTUNITY_VOLUNTEER_STATUS.CONFIRM],
};
export const ManualTabQueryPreset = {
  tabName: VOLUNTEER_TABS.MANUAL,
  status: [OPPORTUNITY_STATUSES.OPEN, OPPORTUNITY_STATUSES.PENDING],
  opportunityTypesList: [OPPORTUNITY_TYPES.MANUAL],
  volunteerStatus: [OPPORTUNITY_VOLUNTEER_STATUS.CONFIRM],
};
export const RejectedTabQueryPreset = {
  tabName: VOLUNTEER_TABS.REJECTED,
  status: [OPPORTUNITY_STATUSES.CANCELED, OPPORTUNITY_STATUSES.OPEN],
  opportunityTypesList: [OPPORTUNITY_TYPES.PRIVATE, OPPORTUNITY_TYPES.PUBLIC, OPPORTUNITY_TYPES.MANUAL],
  volunteerStatus: [OPPORTUNITY_VOLUNTEER_STATUS.REJECT],
};
export const CantMakeItTabQueryPreset = {
  tabName: VOLUNTEER_TABS.CANT_MAKE_IT,
  status: [OPPORTUNITY_STATUSES.OPEN, OPPORTUNITY_STATUSES.CLOSED, OPPORTUNITY_STATUSES.PENDING],
  opportunityTypesList: DEFAULT_OPPO_TYPE_LIST,
  volunteerStatus: [OPPORTUNITY_VOLUNTEER_STATUS.CANT_MAKE_IT],
};
export const PastTabQueryPreset = {
  tabName: VOLUNTEER_TABS.PAST,
  status: [OPPORTUNITY_STATUSES.CLOSED],
  opportunityTypesList: DEFAULT_OPPO_TYPE_LIST,
};
