import {FC, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
//translation
import {useTranslation} from 'react-i18next';
//redux
import {Store} from 'redux/root';
import {getOpportunitySettings, getOrgInfo} from 'redux/organization-service/actions';
import {getDashboardInfo} from 'redux/dashboard-service/action';
import {
  selectorGetOrgActiveStatus,
  selectorGetOrgGeneralType,
  selectorGetOrgId,
} from 'redux/organization-service/selector';
//urls
import {urls} from 'core/appUrls';
//types
import {ORGANISATION_ACTIVE_STATUS} from '@joc/api-gateway';
//components
import DashboardTable from 'components/Organization/Dashboard/DashboardTable';
//styles
import styles from './Dashboard.module.scss';

const DashboardPage: FC = () => {
  const printRef = useRef<HTMLDivElement>(null);

  const {t} = useTranslation(['dashboard', 'messages']);

  const orgId = useSelector((store: Store) => selectorGetOrgId(store));
  const orgStatus = useSelector((store: Store) => selectorGetOrgActiveStatus(store));
  const orgType = useSelector((store: Store) => selectorGetOrgGeneralType(store));

  const dispatch = useDispatch();

  useEffect(() => {
    orgType === 'SCHOOL' ? localStorage.setItem('school', 'true') : localStorage.removeItem('school');

    if (orgId) {
      dispatch(getDashboardInfo(+orgId));
      dispatch(getOpportunitySettings(+orgId));
    } else {
      const localStorageOrgId = localStorage.getItem('organisationId');
      if (localStorageOrgId) {
        dispatch(getOrgInfo(localStorageOrgId));
        dispatch(getDashboardInfo(+localStorageOrgId));
        dispatch(getOpportunitySettings(+localStorageOrgId));
      }
    }
  }, [orgId]);

  return (
    <div className={styles.container}>
      {orgStatus?.status === ORGANISATION_ACTIVE_STATUS.SUSPENDED && (
        <div className={styles.warning}>
          <h2 className={styles.warning__title}>{t('messages:organizationSuspendedDashboard')}</h2>
          <p className={styles.warning__content}>
            {t('messages:toResumeContact')}{' '}
            <Link to={urls.getInTouch} className={styles.warning__link}>
              {t('messages:superadmin')}
            </Link>
          </p>
        </div>
      )}
      <div className={styles.block_title}>
        <h1 className="title">{t('dashboard:title')}</h1>
      </div>
      <DashboardTable ref={printRef} />
    </div>
  );
};

export default DashboardPage;
