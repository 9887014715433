import * as Yup from 'yup';

export const YearlyGoalsValidationSchema = Yup.object().shape({
  gradeId: Yup.number().notRequired(),
  actYearlyGoal: Yup.number()
    .max(999999, 'Input limit exceeded: Please enter a number with no more than 6 digits.')
    .notRequired(),
  hourYearlyGoal: Yup.number()
    .max(999999, 'Input limit exceeded: Please enter a number with no more than 6 digits.')
    .notRequired(),
});
