import moment from 'moment';
import {Channel} from 'stream-chat';
//functions
import {getIsHebrew} from 'core/functions';
//translate
import i18n from 'components/translate';

export const getTimeStamp = (channel: Channel): string => {
  const dateOfLastMessage = channel.state.last_message_at;

  const isToday = moment(dateOfLastMessage).diff(moment(), 'days') === 0;

  if (!isToday) return '';

  const timeStamp = dateOfLastMessage
    ? `${moment(dateOfLastMessage).format(getIsHebrew(i18n.language) ? 'HH:mm' : 'h:mm A')}`
    : '';

  return timeStamp;
};

type DayStamp = {
  day: string;
  month: string;
  year: string;
};

export const getDayStamp = (channel: Channel): DayStamp | string => {
  const dateOfLastMessage = channel.state.last_message_at;
  const dayDifference = moment(dateOfLastMessage).diff(moment(), 'days');

  const isInvalidDay = isNaN(dayDifference);
  if (isInvalidDay) return ''; //if date is invalid return empty string

  const isYesterday = dayDifference === -1;
  const isToday = dayDifference === 0;

  if (isToday) return '';

  const isCurrentWeek = dayDifference >= -7;
  const formatTime = isCurrentWeek ? 'dddd' : 'DD MMM YYYY';

  const chatT = i18n.getFixedT(null, 'chat');
  const yesterdayTitle = chatT('yesterday');

  const dayStamp = isYesterday ? yesterdayTitle : moment(dateOfLastMessage).format(formatTime);

  if (isCurrentWeek) return dayStamp;

  const [day, month, year] = dayStamp.split(' ');

  const result: DayStamp = {day, month, year};

  return result;
};

export const getContentMessage = (latestMessage: string | undefined) => latestMessage || 'Send a message';

export const getIsChannelSelected = (channelId: string | undefined, activeChannelId: string | undefined) =>
  !!channelId && !!activeChannelId && channelId === activeChannelId;
