import {FC, FocusEventHandler, FocusEvent, ChangeEventHandler, InputHTMLAttributes, ChangeEvent} from 'react';
//types
import {FieldBaseProps} from 'core/types/field';
//components
import FieldWrapper from 'shared/inputs/FieldWrapper';
//styles
import InputBase from './InputBase';

type InputProps = FieldBaseProps<
  Pick<InputHTMLAttributes<HTMLInputElement>, 'type' | 'placeholder' | 'min' | 'max' | 'onKeyPress'> & {
    isPencilHidden?: boolean;
    onFocus?: () => void;
    onBlur?: FocusEventHandler<HTMLInputElement>;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    autocomplete?: string;
  }
>;

const Input: FC<InputProps> = ({type, placeholder, min, max, isPencilHidden, ...fieldBaseProps}) => {
  const {field, disabled, dir, onFocus, onKeyPress, onChange, onBlur, autocomplete, form} = fieldBaseProps;

  const {setFieldTouched, setFieldValue, touched} = form;

  const onFocusHandler = () => {
    if (onFocus) onFocus();
  };

  const onBlurHandler = (e: FocusEvent<HTMLInputElement>) => {
    if (onBlur) onBlur(e);
    setFieldTouched(field.name);
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e);
    else if (type === 'number') {
      let value = e.target.value;
      if (value.length > 1 && value.startsWith('0')) {
        value = value.replace(/^0+/, '');
      }

      if (!isNaN(Number(value)) && Number(value) >= 0) {
        setFieldValue(field.name, value).then();
      }
    } else {
      field.onChange(e);
    }
  };

  return (
    <FieldWrapper {...fieldBaseProps}>
      <InputBase
        {...field}
        id={field.name}
        className={fieldBaseProps.parentClassName}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        onKeyPress={onKeyPress}
        type={type}
        onChange={onChangeHandler}
        placeholder={placeholder}
        disabled={disabled}
        min={min}
        max={max}
        isPencilHidden={isPencilHidden}
        dir={dir}
        autoComplete={autocomplete}
      />
    </FieldWrapper>
  );
};
export default Input;
