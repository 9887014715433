import {urls} from 'core/appUrls';
import {LINKS_URLS} from './LINK_URLS';

export const NOT_REDIRECTED_URLS = [
  LINKS_URLS.feedViewLink,
  LINKS_URLS.confirmInvite,
  LINKS_URLS.publicDashboard,
  urls.landing,
  urls.onboardingChooseUserType,
  urls.onboardingChooseStoreType,
  urls.volGetInTouch,
  urls.volAbout,
  urls.onboardingGreeting,
  urls.onboardingLoginVolunteer,
  urls.onboardingLoginOrganization,
  urls.onboardingLoginFromEmail,
  urls.onboardingRecoveryPassword,
  urls.onboardingSignupCongrat,
  urls.onboardingCongrat,
  urls.onboardingSignupVolunteer,
  urls.badGateway,
  urls.accessDenied,
  urls.terms,
  urls.cookies,
  urls.about,
  urls.policy,
  urls.volGetInTouch,
  urls.paymentSuccess,
  urls.tariffPlans,
  urls.onboardingConnectionError,
  urls.getInTouch,
  urls.onboardingAcceptInviteCongrats,
  urls.onboardingAcceptInviteError,
  urls.verification,
  urls.onboardingClaimJoin,
  urls.onboardingClaimPending,
  urls.onboardingClaimCongratulations,
];
