import {FC, useCallback, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';
import ButtonBase from '@material-ui/core/ButtonBase/ButtonBase';
//redux
import {changeStatusVolunteers} from 'redux/opportunities-service/action';
//images
import {ReactComponent as CheckboxSvg} from 'assets/image/checkbox-arrow.svg';
//types
import {
  OPPORTUNITY_VOLUNTEER_STATUS,
  IGetVolunteerByIdResponse,
  StatusOpportunityVolunteersRequestStatus,
} from '@joc/api-gateway';
//styles
import styles from './index.module.scss';

type ButtonConfirmAllPParentrops = {
  opportunityId: number;
  volunteersArray: Array<IGetVolunteerByIdResponse>;
  disabled?: boolean;
};

const ButtonConfirmAll: FC<ButtonConfirmAllProps> = ({
  volunteersArray,
  opportunityId,
  changeStatusVolunteers,
  disabled,
}: ButtonConfirmAllProps) => {
  const [filteredVolunteers, setFilteredVolunteers] = useState<Array<IGetVolunteerByIdResponse>>([]);

  const confirmAllVolunteers = useCallback(async () => {
    if (filteredVolunteers.length) {
      const volunteersIds = filteredVolunteers.map((volunteer) => +volunteer.id);
      try {
        await changeStatusVolunteers(opportunityId, volunteersIds, StatusOpportunityVolunteersRequestStatus.CONFIRM);
      } catch (error) {
        console.error(error);
      }
    }
  }, [filteredVolunteers]);

  useEffect(() => {
    setFilteredVolunteers(
      volunteersArray.filter(
        (i) => i.status === OPPORTUNITY_VOLUNTEER_STATUS.REJECT || i.status === OPPORTUNITY_VOLUNTEER_STATUS.PENDING
      )
    );
  }, [volunteersArray]);

  const {t} = useTranslation('buttons');

  const confirmAll = t('button.confirmAll');

  return (
    <ButtonBase
      id="confirm_button"
      className={cx(styles.btn_default, styles.confirm, (!filteredVolunteers.length || disabled) && styles.disabled)}
      onClick={confirmAllVolunteers}
      disabled={!filteredVolunteers.length || disabled}
    >
      <CheckboxSvg />
      {confirmAll}
    </ButtonBase>
  );
};

const mapDispatchToProps = {
  changeStatusVolunteers,
};
const connector = connect(null, mapDispatchToProps);
type ButtonConfirmAllProps = ConnectedProps<typeof connector> & ButtonConfirmAllPParentrops;

export default connector(ButtonConfirmAll);
