import {FC, useEffect} from 'react';
import {Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

// redux
import {getOpportunitySettings, updateOpportunitySettings} from '../../../../redux/organization-service/actions';
import {selectorGetOppoSettings, selectorGetOrgId} from '../../../../redux/organization-service/selector';

// context
import {useOrganizationSettingsContext} from '../context';

// components
import Loader from '../../../../shared/components/Loader';
import LogYourChasedForm from './LogYourChasedForm';

// styles
import styles from '../OrganizationSetting.module.scss';
import {IOrganisationOpportunitySettingsResponse} from '@joc/api-gateway/lib/api-client';

const LogYourChesed: FC = () => {
  const {t} = useTranslation(['settings']);
  const dispatch = useDispatch();
  const {isUpdateSuccess, setIsUpdateSuccess} = useOrganizationSettingsContext();

  const orgId = useSelector(selectorGetOrgId);
  const opportunitySettings = useSelector(selectorGetOppoSettings);

  useEffect(() => {
    if (!opportunitySettings) {
      if (orgId) {
        dispatch(getOpportunitySettings(orgId));
      }
    }
  }, [orgId, opportunitySettings]);

  const submitHandler = (values: IOrganisationOpportunitySettingsResponse) => {
    dispatch(updateOpportunitySettings(values));
    setIsUpdateSuccess(true);
  };

  return (
    <div className={styles.organisationSettings__tab}>
      <div className={styles.organisationSettings__tab__title}>{t('settings:logYourChased.subTitle')}</div>
      {opportunitySettings ? (
        <Formik initialValues={opportunitySettings} onSubmit={submitHandler}>
          <LogYourChasedForm />
        </Formik>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default LogYourChesed;
