import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

// api
import {ORGANISATION_ACTIVE_STATUS} from '@joc/api-gateway';

// redux
import {connect, ConnectedProps} from 'react-redux';
import {resetError} from '../../../../redux/error-service/action';
import {selectorGetOrgActiveStatus} from '../../../../redux/organization-service/selector';
import {selectorGetError} from '../../../../redux/error-service/selector';
import {Store} from '../../../../redux/root';

// context
import {useOrganizationSettingsContext} from '../context';

// components
import ResponseFailure from '../../../../shared/components/ResponseFailure';
import OrgInfoForm from '../../../../components/Organization/EditForms/OrgInfoForm';

// styles
import styles from '../OrganizationSetting.module.scss';

const OrganizationInfo: FC<EditOrgProps> = ({error, resetError, orgStatus}: EditOrgProps) => {
  const {t} = useTranslation(['editOrg', 'settings', 'errors', 'messages']);
  const {setIsUpdateSuccess} = useOrganizationSettingsContext();

  const isOrgSuspended = orgStatus?.status === ORGANISATION_ACTIVE_STATUS.SUSPENDED;

  return (
    <div className={styles.organisationSettings__tab}>
      <div className={styles.organisationSettings__tab__title}>{t('settings:info.subTitle')}</div>
      {error.state ? (
        <ResponseFailure
          message={error.message}
          buttonClickHandler={resetError}
          buttonTitle={t('errors:checkAndTryAgain')}
          styleTable
        />
      ) : (
        <div className={styles.organisationSettings__tab__organizationInfo}>
          <OrgInfoForm setIsUpdateSuccess={setIsUpdateSuccess} isOrgSuspended={isOrgSuspended} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (store: Store) => ({
  error: selectorGetError(store),
  orgStatus: selectorGetOrgActiveStatus(store),
});

const mapDispatchToProps = {
  resetError,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type EditOrgProps = ConnectedProps<typeof connector>;

export default connector(OrganizationInfo);
