import React, {Dispatch, MutableRefObject, SetStateAction} from 'react';
import Picker from '@emoji-mart/react';
import {Emoji} from 'emoji-mart/dist-es/utils/data';
//translation
import {useTranslation} from 'react-i18next';
//helpers
import {getTranslationEmojiPicker} from '../helpers';
//styles
import './CustomEmojiPicker.scss';

type CustomEmojiPickerProps = {
  onSelectEmoji: (emoji: Emoji) => void;
  emojiPickerRef: MutableRefObject<HTMLDivElement | null>;
  isOpen: boolean;
  changeIsOpenStatus: Dispatch<SetStateAction<boolean>>;
};

const CustomEmojiPicker: React.FC<CustomEmojiPickerProps> = ({
  onSelectEmoji,
  emojiPickerRef,
  isOpen,
  changeIsOpenStatus,
}: CustomEmojiPickerProps) => {
  const {i18n} = useTranslation();

  const translation = getTranslationEmojiPicker(i18n);

  return isOpen ? (
    <div className="emojiPicker">
      <Picker
        onEmojiSelect={onSelectEmoji}
        emojiPickerRef={emojiPickerRef}
        i18n={translation}
        onClickOutside={changeIsOpenStatus}
      />
    </div>
  ) : (
    <></>
  );
};

export default CustomEmojiPicker;
