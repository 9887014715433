import {FC} from 'react';
import styles from './ProgressBar.module.scss';

interface IProgressBarProps {
  progress: number;
}

export const ProgressBar: FC<IProgressBarProps> = ({progress}) => {
  return (
    <div className={styles.progressBarContainer}>
      <div
        className={styles.progressFill}
        style={{display: progress === 0 ? 'none' : 'block', width: `${progress}%`}}
      />
    </div>
  );
};
