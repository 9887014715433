import {FC} from 'react';
import {Field, FieldMetaProps, Form, useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';

// components
import Checkbox from '../../../../shared/components/Checkbox';
import ButtonDefault from '../../../../shared/components/Buttons/ButtonsDefault';

// types
import {IOrganisationOpportunitySettingsResponse} from '@joc/api-gateway/lib/api-client';

// styles
import styles from '../OrganizationSetting.module.scss';

const LogYourChasedForm: FC = () => {
  const {t} = useTranslation(['buttons', 'signUpUser', 'tableHeaders', 'form', 'settings']);
  const {setFieldValue, setFieldTouched, dirty, initialValues} =
    useFormikContext<IOrganisationOpportunitySettingsResponse>();

  const changeTerms = (fieldName: string, e: boolean) => {
    setFieldValue(fieldName, e).then();
    setFieldTouched(fieldName, true).then();
  };

  return (
    <Form className={cx(styles.organisationSettings__tab__form, styles.organisationSettings__tab__form__LogYourChased)}>
      <div className={styles.organisationSettings__tab__form_item}>
        <label
          className={cx(
            styles.organisationSettings__tab__form_item__label,
            styles.organisationSettings__tab__form__LogYourChased_label
          )}
          htmlFor="gradeId"
        >
          {t('settings:logYourChased.description')}
        </label>
        <Field name="mandatoryDescription">
          {({meta}: {meta: FieldMetaProps<boolean>}) => (
            <Checkbox
              changeHandle={(e) => changeTerms('mandatoryDescription', e)}
              error={meta.touched && !!meta.error && !meta.value}
              label={t('form:opportunity.description')}
              classNames={styles.organisationSettings__tab__form__LogYourChased_checkbox__title}
              defaultChecked={meta.value}
            />
          )}
        </Field>
        <Field name="mandatoryTag">
          {({meta}: {meta: FieldMetaProps<boolean>}) => (
            <Checkbox
              changeHandle={(e) => changeTerms('mandatoryTag', e)}
              error={meta.touched && !!meta.error && !meta.value}
              label={t('settings:logYourChased.addCategory')}
              classNames={styles.organisationSettings__tab__form__LogYourChased_checkbox__title}
              defaultChecked={meta.value}
            />
          )}
        </Field>
        <Field name="mandatoryDuration">
          {({meta}: {meta: FieldMetaProps<boolean>}) => (
            <Checkbox
              changeHandle={(e) => changeTerms('mandatoryDuration', e)}
              error={meta.touched && !!meta.error && !meta.value}
              label={t('tableHeaders:duration')}
              classNames={styles.organisationSettings__tab__form__LogYourChased_checkbox__title}
              defaultChecked={meta.value}
            />
          )}
        </Field>
      </div>
      <div className={styles.organisationSettings__tab__form_item}>
        <label
          className={cx(
            styles.organisationSettings__tab__form_item__label,
            styles.organisationSettings__tab__form__LogYourChased_label
          )}
          htmlFor="gradeId"
        >
          Share Your Impact
        </label>
        <Field name="mandatoryReflection">
          {({meta}: {meta: FieldMetaProps<boolean>}) => (
            <Checkbox
              changeHandle={(e) => changeTerms('mandatoryReflection', e)}
              error={meta.touched && !!meta.error && !meta.value}
              label={t('settings:logYourChased.reflection')}
              classNames={styles.organisationSettings__tab__form__LogYourChased_checkbox__title}
              defaultChecked={meta.value}
            />
          )}
        </Field>
        <Field name="mandatoryPhotos">
          {({meta}: {meta: FieldMetaProps<boolean>}) => (
            <Checkbox
              changeHandle={(e) => changeTerms('mandatoryPhotos', e)}
              error={meta.touched && !!meta.error && !meta.value}
              label={t('settings:logYourChased.addPhotos')}
              classNames={styles.organisationSettings__tab__form__LogYourChased_checkbox__title}
              defaultChecked={meta.value}
            />
          )}
        </Field>
      </div>
      <div className={styles.organisationSettings__tab__form_item}>
        <label
          className={cx(
            styles.organisationSettings__tab__form_item__label,
            styles.organisationSettings__tab__form__LogYourChased_label
          )}
          htmlFor="gradeId"
        >
          {t('form:proposeManOppo.verification')}
        </label>
        <Field name="mandatoryRecipientName">
          {({meta}: {meta: FieldMetaProps<boolean>}) => (
            <Checkbox
              changeHandle={(e) => changeTerms('mandatoryRecipientName', e)}
              error={meta.touched && !!meta.error && !meta.value}
              label={t('settings:logYourChased.recipientName')}
              classNames={styles.organisationSettings__tab__form__LogYourChased_checkbox__title}
              defaultChecked={meta.value}
            />
          )}
        </Field>
        <Field name="mandatoryRecipientEmail">
          {({meta}: {meta: FieldMetaProps<boolean>}) => (
            <Checkbox
              changeHandle={(e) => changeTerms('mandatoryRecipientEmail', e)}
              error={meta.touched && !!meta.error && !meta.value}
              label={t('signUpUser:email')}
              classNames={styles.organisationSettings__tab__form__LogYourChased_checkbox__title}
              defaultChecked={meta.value}
            />
          )}
        </Field>
        <Field name="mandatoryRecipientPhone">
          {({meta}: {meta: FieldMetaProps<boolean>}) => (
            <Checkbox
              changeHandle={(e) => changeTerms('mandatoryRecipientPhone', e)}
              error={meta.touched && !!meta.error && !meta.value}
              label={t('settings:logYourChased.recipientPhone')}
              classNames={styles.organisationSettings__tab__form__LogYourChased_checkbox__title}
              defaultChecked={meta.value}
            />
          )}
        </Field>
      </div>
      <div className={cx(styles.button__LogYourChesed__wrapper)}>
        <ButtonDefault
          submitType
          title={t('buttons:button.save')}
          disabled={!dirty}
          classList={['primary', 'lg']}
          parentClassName={styles.button}
        />
      </div>
    </Form>
  );
};

export default LogYourChasedForm;
