import {FC} from 'react';
import i18next from 'i18next';
import {useTranslation} from 'react-i18next';
//styles
import styles from './TermsInfo.module.scss';

// type ListItem = {title: string; paragraf: string; sublist: Array<string>};
//
// const getListNode = (item: ListItem, index: number) => (
//   <li key={index}>
//     <span>{item.title}</span>
//     <span>{item.paragraf}</span>
//     {item.sublist && (
//       <ul>
//         {item.sublist.map((listItem: any, index: number) =>
//           typeof listItem === 'string' ? (
//             <li key={index}>{listItem}</li>
//           ) : (
//             <li>
//               <span>{listItem.title}</span>
//               <span>{listItem.text}</span>
//               {listItem.sublist && (
//                 <ul>
//                   {listItem.sublist.map((sublistItem: string, index: number) => (
//                     <li key={index}>{sublistItem}</li>
//                   ))}
//                 </ul>
//               )}
//             </li>
//           )
//         )}
//       </ul>
//     )}
//   </li>
// );
//
// const getArr = (key: string): Array<any> => i18next.t(key, {returnObjects: true}) || [];

// const testList = getArr('terms:context.2.content.text');

const TermsInfo: FC = () => (
  <div className={styles.terms}>
    <div className={styles.gray__text}>
      Your use of any of Just One Chesed&apos;s services including our Software as a Service (SaaS) or web site(s)
      (collectively, the &quot;Just One Chesed Service&quot;) and any information, text, graphics, photos, videos, or
      other materials uploaded, downloaded or appearing on the Just One Chesed Service (collectively referred to as
      &quot;Content&quot;) is subject to these Terms and Conditions (the &quot;Terms and Conditions&quot;) in effect at
      the time of your use. These Terms and Conditions are an integral part of the Agreement entered into between Just
      One Chesed, Inc. and the Customer and/or Donor (referred to as “user”, “you”, “your”).
    </div>

    <div className={styles.gray__text}>
      Your use of the Just One Chesed Service, or any of the services or features accessible therein, constitutes your
      acceptance of the Terms. If you are an individual acting as a representative of an organization which wishes to
      use the Just One Chesed Service, then you represent and agree that you have the authority to accept these Terms
      and Conditions on behalf of such an organization.
    </div>

    {/* ///////////////////////////// */}

    <h2 className={styles.title}>VOLUNTEER LIABILITY RELEASE FORM</h2>

    <div className={styles.article__text}>
      In consideration of my desire to serve as a volunteer organized by Just One Chesed.
    </div>

    <div className={styles.article__text}>
      I hereby assume all responsibility for any and all risk of property damage or bodily injury that I may sustain
      while participating in any voluntary work.
    </div>

    <div className={styles.article__text}>
      Further, I, for myself and my heir, executors, administrators and assigns, hereby release, waive and discharge
      Just One Chesed and its officers, directors, employees, agents and volunteers of and from any and all claims which
      I or my heirs, administrators and assigns ever may have against any of the above for, on account of, by reason of
      or arising in connection with such volunteer work or my participation therein, and hereby waive all such claims,
      demands and causes of action.
    </div>

    <div className={styles.article__text}>
      Further, I expressly agree that this release, waiver and indemnity agreement is intended to be as broad and
      inclusive as permitted by the United States and/or Israel, and that if any portion thereof is held invalid, it is
      agreed that the balance shall, notwithstanding, continue in full legal force and effect.
    </div>

    <div className={styles.article__text}>
      I currently have no known mental or physical condition that would impair my capability for full participation as
      intended or expected of me.
    </div>

    <div className={styles.article__text}>
      Further, I have carefully read the foregoing release and indemnification and understand the contents thereof and
      sign up on my own, free act.
    </div>

    {/* ///////////////////////////// */}

    <h2 className={styles.title}>Donors and Fundraisers</h2>

    <ol>
      <li>
        <div className={styles.gray__text}>
          <b>Sole Responsibility:</b> You understand that you are solely responsible for ensuring that you have selected
          the correct organization to fundraise for or donate to and have provided a valid and authorized credit card
          for making the donation within the Just One Chesed Service.
        </div>

        <div className={styles.gray__text}>
          Unrestricted Gifts: You understand that all donations made via the Just One Chesed Service will be deemed
          unrestricted gifts by the receiving organization, and potentially may not be used for the purpose specified by
          you or the organization. Just One Chesed has no control over how the organization uses funds raised via the
          Just One Chesed Service.
        </div>
      </li>
      <li>
        <b>Payment Processing:</b> The amount of a donation to an organization designated by you is charged to your
        credit/debit card or debited from your bank account via ACH and paid to, and processed by the Just One Chesed
        Service and its partner payment processing services. You understand that each donation is held in a
        non-operating bank account, by the third party credit card processor (currently Stripe) and that Just One Chesed
        does not have custody or control of the donations. You understand that the third party credit card processor
        (currently Stripe) aggregates all donations made each day and transfers such total to the each
        organization&apos;s bank account within 5-10 business days for the initial deposit and then on a rolling 2-3
        business days schedule for any payouts thereafter.
      </li>
      <li>
        <b>Non-refundable:</b> You understand that a donation payment, once charged to your credit card, is final and
        non-refundable with the exception that you can prove the transaction was made through an unauthorized use of
        your credit card. If you become aware of unauthorized use of your credit card, or it is lost or stolen, you must
        notify your credit card provider in accordance with its reporting rules.
      </li>
      <li>
        <b>Fundraising on Behalf of Organization:</b> You understand that if you chose to fundraise through the Just One
        Chesed Service on behalf of an organization that has enabled donation features such as general donation
        collection or fundraising campaigns, all monetary gifts to your fundraising efforts go to the associated
        organization and you do not receive any monetary portion of that gift. You understand that if you chose to
        fundraise through the Just One Chesed Service on behalf of an organization that has enabled donation features
        such as general donation collection, fundraising campaigns, etc, you cannot misrepresent that organization or
        any information about that organization or its fundraising efforts. Doing so will violate the code of user
        conduct (see &quot;USER CONDUCT&quot; section).
      </li>
      <li>
        <b>Tax Language:</b> You understand that if you chose to make a monetary gift (i.e. donation) to organizations
        through the Just One Chesed Service, the organization you transact with is the one that sets the tax language in
        any receipt you receive.
      </li>
      <li>
        <b>No Tax Representation or Liability:</b> You understand that the Just One Chesed Service makes no
        representations about the nature of any transaction you make through the Just One Chesed Service. Specifically,
        we do not make any representation as to whether all or any portion of your donations, including, if any,
        processing fees, are tax deductible. Just One Chesed will have no liability for any claim by any federal, state,
        local or any other tax authority with respect to the characterization on any applicable tax return of any
        donation by you, any Just One Chesed user, or any beneficiary organization. Always consult the organization you
        are transacting with and a qualified financial advisor prior to claiming a deduction on your taxes.
      </li>
    </ol>

    {/* ///////////////////////////// */}

    <h2 className={styles.title}>
      General Terms Applicable to Donors, Fundraisers, Nonprofits, and All Other Just One Chesed Users
    </h2>

    <h3 className={styles.sub__title}>User Accounts</h3>

    <ol>
      <li>
        <b>Accessing and Securing User Account:</b> Your user account will be accessed through a user ID (“username”)
        and password that you will create (your &quot;Account Credentials&quot;). Your Account Credentials are solely
        for your use. You are responsible for safeguarding the confidentiality of your Account Credentials that you use
        to access the Just One Chesed Service and you are fully and solely responsible for all activities and actions
        that occur with your Account Credentials, whether authorized by you or not. We encourage to use “strong”
        passwords comprised of a combination of upper and lower alphabetic characters, numbers, and symbols, and at
        least 6 characters in length. It is your responsibility to take adequate precautions with your Account
        Credentials and to immediately notify Just One Chesed of any unauthorized use of your Account Credentials.
      </li>
      <li>
        <b>Access Provided:</b> Using your user account, you may access and participate in the Just One Chesed Service,
        including viewing, posting and responding to communications on and through the Just One Chesed Service.
      </li>
      <li>
        <b>Liability:</b> Just One Chesed cannot and will not be liable for any loss or damage arising from your failure
        to follow and comply with the above user account and password requirements.
      </li>
      <li>
        <b>Account Refusal or Cancellation:</b> Just One Chesed can refuse registration of, or cancel, any user account
        in its sole discretion, at any time.
      </li>
      <li>
        <b>International Use:</b> The Just One Chesed Service is hosted in the United States. If you use the Just One
        Chesed Service from outside of the United States, you acknowledge that you are voluntarily transmitting and
        transferring information (potentially including personally-identifiable information) and Content to the United
        States. Just One Chesed&apos;s receiving, use, storage and sharing of your information and Content is subject to
        the laws of the United States. Additionally, you will comply with all United States laws, rules and regulations
        applicable to the export of products, services, software and technical data regardless of the jurisdiction in
        which you are located.
      </li>
      <li>
        <b>User Account Terminated:</b> Just One Chesed, in its sole discretion, may terminate your password and/or user
        account and remove and discard any Content within the Just One Chesed Service for any reason, including and
        without limitation lack of use, or if Just One Chesed believes that you have violated or acted inconsistently
        with the letter or spirit of these Terms and Conditions. In such event, any contracts, verbal or written or
        assumed, in conjunction with your user account and all its parts, at Just One Chesed&apos;s discretion, will be
        terminated as well.
      </li>
    </ol>

    {/* ///////////////////////////// */}

    <h3 className={styles.sub__title}>User Conduct</h3>

    <ol>
      <li>
        <b>Right to Remove Content and Users:</b> Just One Chesed has the right, but not the obligation, to remove or
        block Content from the Just One Chesed Service that it determines in its sole discretion to be in violation of
        these Terms and Conditions, to be unlawful, offensive, threatening, libelous, defamatory, obscene or otherwise
        objectionable, that violates any party&apos;s intellectual property or that is detrimental to the quality or
        intended spirit of the Just One Chesed Service. Just One Chesed also has the right, but not the obligation, to
        limit or revoke the user privileges of the account of anyone who posts such Content or engages in such behavior.
      </li>
      <li>
        <b>Unacceptable Content:</b> Just One Chesed will use common sense and business sense regarding Content or
        behavior allowed on or through the Just One Chesed Service. Unacceptable Content or behavior include:
        <ul>
          <li>Abuse, harassment, threats, flaming or intimidation of any person or organization.</li>
          <li>Engaging in or contributing to any illegal activity or activity that violates others&apos; rights.</li>
          <li>Use of derogatory, discriminatory or excessively graphic language.</li>
          <li>Providing information that is false, misleading or inaccurate.</li>
          <li>
            Hacking or modifying the Just One Chesed Service or another Web site to falsely imply an association with
            Just One Chesed.
          </li>
          <li>
            Implying or pretending to be affiliated with a company or organization with which you are not affiliated, or
            misrepresenting the extent of your affiliation or role with an affiliated company or organization.
          </li>
          <li>Transmitting worms, viruses or harmful software.</li>
          <li>Sending unwanted messages to other users (aka &quot;spam&quot;).</li>
          <li>Disclosing personal or proprietary information of another person or organization.</li>
        </ul>
      </li>
    </ol>

    {/* ///////////////////////////// */}

    <h3 className={styles.sub__title}>SaaS Services</h3>

    <ol>
      <li>
        <b>Responsibility:</b> You are responsible for your use of the Just One Chesed Service, for any Content you post
        to the Just One Chesed Service, and for any consequences thereof. The Content you submit, post, or display will
        be able to be viewed by other users of the Just One Chesed Service and through third party services and
        websites. You should only submit, post, display, or provide Content that you are comfortable sharing with other
        Just One Chesed Service users under these Terms and Conditions.
      </li>
      <li>
        <b>Use of the Service:</b>

        <ul>
          <li>
            Your use of the Just One Chesed Service is also subject to the Privacy Policy in effect at the time of your
            use, which is incorporated herein as though set forth in full. You can review the most current version of
            the Just One Chesed <a href="https://info.givegab.com/givegabs-privacy-policy">Privacy Policy</a>.
          </li>
          <li>
            You may use the Just One Chesed Service only if you can form a binding contract with Just One Chesed and are
            not a person barred from receiving services under the laws of the United States,
            <span className={styles.red__text}> Israel</span> or other applicable jurisdiction. You may use the Services
            only in compliance with these Terms and Conditions and all applicable local, state, national, and
            international laws, rules and regulations. Where applicable, customer warrants that any Agreement or other
            exhibit and addenda are executed by a duly authorized representative.
          </li>
          <li>
            Your use of the Just One Chesed Service may also be subject to additional terms, conditions, policies and/or
            agreements applicable to a specific Web site, service, software, service level or version. In the event of
            any conflict between these Terms and Conditions and such additional terms, conditions, policies and/or
            agreements, the additional terms, conditions, policies and/or agreements will control. For the avoidance of
            doubt, if there are terms and conditions in these Terms and Conditions regarding subjects on which the
            additional terms, conditions, policies and/or agreements are silent, such silence will not constitute a
            conflict and the terms and conditions in these Terms and Conditions will control.
          </li>
          <li>
            Just One Chesed retains the right to limit usage and storage at our sole discretion at any time without
            advance notice to you.
          </li>
          <li>
            Use of the Just One Chesed Services does not create, and shall not be construed to create, a joint venture,
            partnership, or other formal business relationship between you, the Organization, and Just One Chesed, INc.
            At all times, each shall remain an independent contractor with respect to one another.
          </li>
        </ul>
      </li>
      <li>
        <b>Customer Assistance and Administrator:</b> Customer will provide Just One Chesed the information and
        assistance reasonably necessary for the proper activation and delivery of the Just One Chesed Service. Customer
        grants Just One Chesed a perpetual, non-exclusive license to all Customer information as is necessary to receive
        the Just One Chesed Service. Customer will designate a contact person (&quot;Administrator&quot;) to Just One
        Chesed who will be responsible for administering our relationship. Customer has authorized the Administrator to
        act on Customer’s behalf and to bind Customer for any matters relating to the Agreement and these Terms. Just
        One Chesed understands that Client may need to change the Administrator from time to time.
      </li>
      <li>
        <b>Development Practices:</b> Just One Chesed leverages Agile Software Development practices to iterate on and
        improve the Just One Chesed Service based on feedback from the user community. Therefore the Just One Chesed
        Service is always improving and the form and nature of the Just One Chesed Service may change from time to time
        without prior notice. In addition, Just One Chesed may stop (permanently or temporarily) providing the Just One
        Chesed Service (or any features within the Just One Chesed Service) to you or all general users and may not be
        able to communicate to you with advanced notice.
      </li>
      <li>
        <b>Proprietary Rights, Confidential Information and Intellectual Property</b> (see 5.1)
        <ul>
          <li>
            <b>Ownership of Submissions:</b> You agree that the submission of any ideas, suggestions, documents, and/or
            proposals to Just One Chesed, Inc. through its suggestion, feedback, help, support or similar pages
            including but not limited to Facebook, Twitter, or LinkedIn, is at your own risk. Just One Chesed has no
            obligations (including without limitation obligations of confidentiality) with respect to such Feedback. You
            represent and warrant that you have all rights necessary to submit the Feedback. You hereby grant to Just
            One Chesed, Inc. a fully paid, royalty-free, perpetual, irrevocable, worldwide, non-exclusive, and fully
            sublicensable right and license to use, reproduce, perform, display, distribute, adapt, modify, reformat,
            create derivative works of, and otherwise commercially or non-commercially exploit in any manner, any and
            all Feedback, and to sublicense the foregoing rights.
          </li>
          <li>
            <b>Ownership of SaaS Services:</b> Just One Chesed owns the Just One Chesed Service as a whole, including
            all computer code, graphics, user interfaces and audiovisual content used to provide the Just One Chesed
            Service and the combination of all the elements on the Just One Chesed Service. The Just One Chesed Service
            as a whole, the computer code of the Just One Chesed Service, the user interface, and graphic elements are
            all copyrighted works of Just One Chesed. Various other aspects of the Just One Chesed Service may be
            protected by intellectual property laws including laws of copyright, trademark, service mark, patent and
            trade secret.
          </li>
          <li>
            <b>Restrictions:</b> Just One Chesed owns all right, title and interest in and to the Just One Chesed
            Service. Just One Chesed reserves all rights to the Just One Chesed Service that are not expressly granted
            herein. By way of example only, and not limitation, you do not have the right to modify, adapt, translate,
            or reverse engineer any portion of the Just One Chesed Service and you do not have the right to index or
            aggregate any portion of the Just One Chesed Service (either by hand or by means of a robot, spider, or
            other device). Nothing in this Agreement will be construed as granting you any property rights in the Just
            One Chesed Service or to any invention or any patent, copyright, trademark or other intellectual property
            right that has been issued, or that may issue, based on the Just One Chesed Service.
          </li>
          <li>
            <b>Confidential Information.</b> Except as expressly allowed herein, each party will hold in confidence and
            not use or disclose any Confidential Information of the other party and will similarly bind its employees,
            consultants and contractors in writing. “Confidential Information” means any information, data, or know-how
            relating to a party’s business, products, services, processes, techniques, pricing, internal procedures,
            employees and personnel. Confidential Information specifically excludes any information arising from the use
            of the Just One Chesed Service. Confidential Information does not include information that: (i) the
            receiving party can prove through written documentation was rightfully in its possession at the time of
            disclosure without a confidentiality obligation; (ii) becomes part of the public knowledge not as a result
            of any action or inaction of the receiving party in breach of the Agreement; (iii) is disclosed to the
            receiving party by a third party not in violation of any obligation of confidentiality; or (iv) is
            independently developed by the receiving party without use of or reference to the disclosing party’s
            Confidential Information, which can be proven through written documentation.
          </li>
          <li>
            <b>U.S. Government Restricted Rights. </b> If you are an agency of the United States Government, the Just
            One Chesed Service is a &quot;Commercial Item&quot;, as that term is defined at 48 C.F.R. Section 2.101,
            consisting of &quot;Commercial Computer Software&quot; and &quot;Commercial Computer Software
            Documentation&quot;, as such terms are used in 48 C.F.R. Section 12.212 or 48 C.F.R. Section 227.7202, as
            applicable. Consistent with 48 C.F.R. Section 12.212 or 48 C.F.R. Section 227.7202-1 through 227.7202-4, as
            applicable, the Commercial Computer Software and Commercial Computer Software Documentation are being
            licensed to the United States Government (a) only as Commercial Items and (b) with only those rights as are
            granted to all other end users pursuant to the terms and conditions herein. All unpublished rights are
            reserved under the copyright laws of the United States. For purpose of any public disclosure provision under
            any law, it is agreed that the Just One Chesed Service are trade secrets and proprietary commercial products
            and not subject to disclosure.
          </li>
        </ul>
      </li>
      <li>
        <b>Content Permissions: </b>

        <ul>
          <li>
            <b>Content Ownership: </b> Your text, images, audiovisual media and any other form of intellectual property,
            including the data, ideas, inventions, designs, patterns and processes in such material (collectively,
            &quot;Content&quot;) that you post on or through the Just One Chesed Service belongs to you and you may use
            it in any other way without restriction. If your Content is the subject of a pending or issued patent, you
            have disclosed or will disclose that fact in connection with posting your Content on or through the Just One
            Chesed Service. To the extent you hold a patent in the Content, no license under any patent is herein
            granted. Any license to use patented Content shall be in the form of a separate written contract, in which
            event your, Just One Chesed&apos;s and/or any of its Customers&apos; respective obligations shall be only
            those expressed in such separate written contract. Therefore, you represent and agree to all of the
            following and acknowledge that Just One Chesed and its Customers are explicitly relying on such
            representations and agreement with regard to your Content:
            <ul>
              <li>
                Neither Just One Chesed nor any of its Customers assumes any obligation with respect to any of your
                Content except as set forth in these Terms and Conditions, unless and until it enters into a separate
                written contract with you, and then only as expressed in that separate written contract. In the absence
                of a separate written contract, your rights with regard to Just One Chesed or any of its Customers&apos;
                use of your Content shall be as described in these Terms and Conditions and as existing under the patent
                laws of the United States.
              </li>
              <li>
                You hereby irrevocably release and forever discharge Just One Chesed and its Customers and their
                affiliates and subsidiaries (together, the &quot;Released Parties&quot;) from any and all actions,
                causes of actions, claims, damages, liabilities and demands, whether absolute or contingent and of any
                nature whatsoever, which you now have or hereafter can, shall or may have against the Released Parties
                or their respective successors and assigns with respect to the Content, including without limitation in
                respect of how the Released Parties, directly or indirectly, use the Content, with the sole exception
                that the foregoing release and discharge does not apply to your right to bring a claim of patent
                infringement arising from use of your Content.
              </li>
            </ul>
          </li>
          <li>
            <b>Granting Rights to Content: </b> By using the Just One Chesed Service, you are granting Just One Chesed a
            non-exclusive, worldwide, royalty-free, sublicensable and transferable right and license to use, reproduce,
            create derivative works of, distribute, publicly perform and publicly display your Content on and through
            the Just One Chesed Service and on and through services affiliated with Just One Chesed, regardless of the
            form of media used or of whether such services now exist or are developed in the future. If you post Content
            on or through the Just One Chesed Service, you represent and warrant that you have the right to post that
            Content and to grant the above rights to Just One Chesed. Neither Just One Chesed nor any of its Customers
            has any obligation, either express or implied, to make any use of your Content. However, if Just One Chesed
            and/or any of its Customers do make use of your Content, whether such use is intentional or inadvertent, no
            compensation will be due to you or anyone else for the use of your Content or the use of any data, ideas,
            inventions, designs, patterns.
          </li>
          <li>
            <b>Posting Content is Voluntary: </b> You understand that posting your Content on or through the Just One
            Chesed Service is entirely voluntary and will expose your Content to public display in a non-confidential
            manner. You understand that Just One Chesed and/or any past, present or future Customer of Just One Chesed
            (each, a &quot;Customer&quot;) may view your Content and may develop or have developed Content which is
            identical or similar to yours, may already know of such Content from other sources or may have taken or will
            take some other action with respect to such Content. The Content represents your own original work and you
            have all necessary rights to disclose the Content. In doing so, you are not violating the rights of any
            third party and you know of no other individual or entity whose rights will be infringed by the Content
            being viewed and used as described in these Terms and Conditions. Whether privately or publicly posted, all
            Content you provide is the sole responsibility of you, the originator. We cannot take responsibility for
            such Content. Any use or reliance on any Content or materials posted via the Just One Chesed Service or
            obtained by you through the Just One Chesed Service is at your own risk.
          </li>
          <li>
            <b>Ownership of Existing Content: </b> Content posted by another person or company on or through the Just
            One Chesed Service belongs to the poster. Except as expressly permitted in these Terms and Conditions, you
            do not have the right to use, reproduce, create derivative works of, distribute, publicly perform or
            publicly display any Content that does not belong to you, other than viewing of the Content on or through
            the Just One Chesed Service as Just One Chesed may make available.
          </li>
          <li>
            <b>No Content Liability: </b> Just One Chesed does not endorse, support, represent or guarantee the
            completeness, truthfulness, accuracy, or reliability of any Content or communications posted via the Just
            One Chesed Service or endorse any opinions expressed via the Services. You understand that by using the Just
            One Chesed Service, you may be exposed to Content that might be offensive, harmful, inaccurate or otherwise
            inappropriate, or in some cases, postings that have been mislabeled or are otherwise deceptive. Under no
            circumstances will Just One Chesed be liable in any way for any Content, including, but not limited to, any
            errors or omissions in any Content, or any loss or damage of any kind incurred as a result of the use of any
            Content posted, emailed, transmitted or otherwise made available via the Just One Chesed Service or
            broadcast elsewhere.
          </li>
          <li>
            <b>Other websites: </b> Just One Chesed does not endorse, support, represent or guarantee any links to
            external websites. Other websites are provided as a convenience to you. You understand that Just One Chesed
            has not reviewed all of these other websites, and therefore has no responsibility for the content of such
            other websites and shall not be liable for any damages or injury arising from the content of these other
            websites. You understand that, except for information, products or services clearly identified as being
            supplied by the Just One Chesed Service, we do not operate, control or endorse any information, products or
            services on the Internet in any way. The Just One Chesed service does not endorse or make any
            representations about these other websites, or any information or other products or materials found on these
            other websites, or any results that may be obtained from using these other websites. If you decide to access
            any of these other websites linked to the Just One Chesed Service, you do so entirely at your own risk.
          </li>
          <li>
            <b>Content Disclosure: </b> Just One Chesed may preserve your Content and may also disclose such Content,
            with or without notice to you, if required to do so by law or in the good-faith belief that such
            preservation or disclosure is reasonably necessary to: (i) comply with legal process; (ii) enforce these
            Terms and Conditions; (iii) respond to claims that your Content violates the rights of any third party; or
            (iv) protect the rights, property, or personal safety of Just One Chesed, its users, and the public.
          </li>
        </ul>
      </li>
    </ol>

    {/* ///////////////////////////// */}

    <h3 className={styles.sub__title}>Copyright Infringement</h3>

    <ol>
      <li>
        <b>Copyright Infringement Notices: </b> If you believe that any material available on or through the Just One
        Chesed Service violates your copyright, you may send Just One Chesed a copyright infringement notice. Section
        512(c) of the Copyright Act requires that your notice must be in writing and must include substantially all of
        the following:
        <ul>
          <li>
            A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive
            right that is allegedly infringed.
          </li>
          <li>
            Identification of the copyrighted work claimed to have been infringed, or if multiple copyrighted works at a
            single online site are covered by a single notification, a representative list of such works at that site.
          </li>
          <li>
            Identification of the material that is claimed to be infringing or to be the subject of infringing activity
            and that is to be removed or access to which is to be disabled, and information reasonably sufficient to
            permit Just One Chesed to locate the material. Just One Chesed requests that complete URLs for each instance
            of the allegedly infringing material be provided.
          </li>
          <li>
            Information reasonably sufficient to permit Just One Chesed to contact you, such as an address, telephone
            number, and, if available, an electronic mail address at which you may be contacted.
          </li>
          <li>
            A statement that you have a good faith belief that use of the material in the manner complained of is not
            authorized by the copyright owner, its agent, or the law.
          </li>
          <li>
            A statement that the information in the copyright infringement notice is accurate, and under penalty of
            perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly
            infringed.
          </li>
          <li>
            Your written copyright infringement notice must be sent to Just One Chesed&apos;s designated copyright agent
            via mail. Please be aware that Section 512(f) of the Copyright Act provides that any person who knowingly
            materially misrepresents that material or activity is infringing may be subject to liability.
          </li>
        </ul>
      </li>
      <li>
        <b>Copyright Abuse Policy: </b> Just One Chesed will terminate, in appropriate circumstances, account holders of
        Just One Chesed&apos;s system or network who are repeat copyright infringers.
      </li>
      <li>
        <b>Designated Copyright Agent: </b> Copyright Agent, Just One Chesed, Inc., 119 S. Cayuga St., Suite 403,
        Ithaca, NY 14850
      </li>
      <li>
        <b>Copyright Agent Contact: </b> The Copyright Agent should only be contacted if you believe that your work has
        been used or copied in a way that constitutes copyright infringement and such infringement is occurring on or
        through the Just One Chesed Service. The Copyright Agent will not respond to any other inquiries.
      </li>
    </ol>

    {/* ///////////////////////////// */}

    <h3 className={styles.sub__title}>Fees, Fundraising and Monetary Gifts (i.e., Donating)</h3>

    <ol>
      <li>
        <b>Transaction Fees: </b> The Just One Chesed Service charges a transaction fee to organizations which have
        chosen to leverage donation features such as general donation collection, fundraising campaigns, crowdfunding,
        etc. Fees are laid out on our <a href="https://info.givegab.com/pricing">pricing page</a>. You understand that
        the transaction fee structure is subject to change at the sole discretion of Just One Chesed. All previous
        donations will not be affected by any changes.
      </li>
      <li>
        <b>Cover the Fees Option: </b> The Just One Chesed Service provides users the option to cover the transaction
        fees associated with a donation, on a per-donation basis. You understand that if you choose to cover the
        transaction fees for a donation, the transaction fees will be added to your intended donation, with the total
        being charged to your credit/debit card or checking account in the case of ACH debits. This will result in the
        organization receiving 100% of your intended donation.
      </li>
      <li>
        <b>501(c) Status Verification. </b> You understand that your organization will be required to provide additional
        information for verification of the organization, its 501(c) status and its ability to collect donations,
        including but not limited to EIN.
      </li>
      <li>
        <b>Payment Processor Setup Requirements. </b> You understand that as a representative of your organization you
        will be required to provide personally identifiable information for underwriting purposes so that our third
        party payment processor can verify you are a valid representative of that Organization, including but not
        limited to your full name, your date of birth, and the last 4 of our social security number (U.S. only). You
        agree to not provide invalid or personally identifiable information for which you are unauthorized.
        Additionally, you will be required to provide bank routing and account number information for the organization,
        as well as the organization representative’s name listed on the bank account in order to be paid out any
        escrowed donations. You agree not to provide invalid or bank account information for which you are unauthorized.
      </li>
      <li>
        <b>Stripe Terms. </b> Your use of the Just One Chesed Service is also subject to the terms and conditions of our
        third party payment processor Stripe and their Stripe Connected Account Agreement, in effect at the time of your
        use, which is incorporated herein as though set forth in full. You can review the most current version of the{' '}
        <a href="https://stripe.com/us/connect-account/legal">Stripe Connected Account Agreement</a> here.
      </li>
      <li>
        <b>Non-refundable Donations. </b> You understand that a donation payment, once charged to a user’s credit card
        or checking account, is final and non-refundable with the exception that they can prove the transaction was made
        through an unauthorized use of their credit card or bank account.
      </li>
      <li>
        <b>Donation Handling Using Third Party Credit Card Processor Stripe. </b> You understand that each donation is
        held in a non-operating bank account, by the third party credit card processor (currently Stripe) and that Just
        One Chesed does not have custody or control of the donations. These funds are held until such time that the
        payment processor disburses them to the organization. You understand that the Just One Chesed Service does not
        and will not have custody or control of the actual contributions and that all funds are handled by the third
        party credit card processor (currently Stripe). You understand aggregates all donations made each day and
        transfers such total to the each organization&apos;s bank account at frequencies determined by Just One Chesed
        and the organization. By default this happens on a 2-3 business day rolling basis.
      </li>
      <li>
        <b>Fundraising Responsibilities. </b> Organizations may set fundraising goals or require their fundraisers to
        agree to raise a certain amount of money by a certain date. Just One Chesed is not liable if fundraisers or
        fundraising do not meet their goals and makes no guarantees about the success of any fundraising goals. Any
        shortfalls, issues, disputes or questions related to fundraisers’ goals, deadlines, andor shortfalls are between
        the applicable user and the organization. The user and the organization all expressly agree that Just One Chesed
        shall have no responsibility or liability related to fundraising.
      </li>
      <li>
        <b>No Donation Portion to Fundraiser. </b> You understand as a representative of the organization that if a user
        chooses to fundraise through the Just One Chesed Service on behalf of the organization, all donations go to the
        organization and the user does not receive any monetary portion of any donations.
      </li>
    </ol>

    {/* ///////////////////////////// */}

    <h3 className={styles.sub__title}>Cancellation and Termination</h3>

    <ol>
      <li>
        <b>Just One Chesed Service Discontinued. </b> Just One Chesed, in its sole discretion and at any time, may
        discontinue providing the Just One Chesed Service, or any part thereof, with or without notice. Any termination
        of your access to the Just One Chesed Service under any provision of these Terms and Conditions may be effected
        without prior notice. Just One Chesed may immediately deactivate or delete your user account, as applicable, and
        all related information and Content and bar any further access to such information, Content or to the Just One
        Chesed Service. Just One Chesed will not be liable to you or any third party for any termination of your access
        to the Just One Chesed Service.
      </li>
      <li>
        <b>No Access After Account Termination. </b> After cancellation or termination of your account for any reason,
        you will no longer have access to your user account and all information and Content in your user account or that
        you have stored on the Just One Chesed Service may be but is not required to be, deleted by Just One Chesed.
        Just One Chesed will have no liability for information or Content that is deleted due to the cancellation or
        termination of your user account for any reason. If you are a member of an organization, and that organization
        community is canceled or terminated, Content posted to that organization will no longer be available to you.
        Such Content may be, but is not required to be, deleted by Just One Chesed. Just One Chesed will have no
        liability for information or Content that is deleted due to the cancellation or termination of an organization
        community.
        <ul>
          <li>
            Additionally, for the Enterprise Product, the connection to the customer’s payment gateway or financial
            transaction process will be removed from the customer admin control panel.
          </li>
        </ul>
      </li>
    </ol>

    {/* ///////////////////////////// */}

    <h3 className={styles.sub__title}>Liability</h3>

    <div className={styles.gray__text}>
      <b>WARRANTY DISCLAIMER:</b> THE JUST ONE CHESED SERVICE IS PROVIDED &quot;AS IS&quot; WITH NO REPRESENTATIONS OR
      WARRANTIES OF ANY KIND, EXPRESS, STATUTORY OR IMPLIED, AS TO THE OPERATION OF THE JUST ONE CHESED SERVICE, OR THE
      INFORMATION, CONTENT, MATERIALS, OR PRODUCTS INCLUDED ON THE JUST ONE CHESED SERVICE. TO THE FULLEST EXTENT
      PERMISSIBLE BY APPLICABLE LAW, JUST ONE CHESED AND ITS AFFILIATES DISCLAIM ALL WARRANTIES, EXPRESS, STATUTORY, OR
      IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE
      AND NON-INFRINGEMENT. FURTHER, JUST ONE CHESED AND ITS AFFILIATES DO NOT WARRANT THE ACCURACY OR COMPLETENESS OF
      THE INFORMATION, TEXT, GRAPHICS, LINKS OR OTHER INFORMATION CONTAINED IN THE JUST ONE CHESED SERVICE. SOME
      JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. JUST
      ONE CHESED DOES NOT WARRANT THAT THE JUST ONE CHESED SERVICE WILL BE AVAILABLE AT ANY TIME OR FROM ANY PARTICULAR
      LOCATION, WILL BE SECURE OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE JUST ONE CHESED SERVICE IS
      FREE OF VIRUSES OR OTHER POTENTIALLY HARMFUL COMPONENTS. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN,
      OBTAINED FROM JUST ONE CHESED OR THE JUST ONE CHESED SERVICE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN
      THESE TERMS AND CONDITIONS.
    </div>

    <div className={styles.gray__text}>
      <b>DISCLAIMER OF CONSEQUENTIAL DAMAGES:</b> NEITHER JUST ONE CHESED NOR ITS AFFILIATES WILL BE LIABLE, UNDER ANY
      THEORY OF LAW, FOR ANY INDIRECT, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES, INCLUDING, BUT NOT LIMITED TO
      LOSS OF PROFITS, BUSINESS INTERRUPTION, AND/OR LOSS OF INFORMATION OR DATA. SOME JURISDICTIONS DO NOT ALLOW THE
      EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS AND EXCLUSIONS MAY NOT
      APPLY TO YOU.
    </div>

    {/* ///////////////////////////// */}

    <h3 className={styles.sub__title}>General Terms</h3>

    <ol>
      <li>
        <b>Entire Agreement: </b> These Terms and Conditions (including any additional terms, conditions, policies and
        agreements incorporated herein), in combination with any existing Master Service Agreement between Just One
        Chesed and the customer, are the entire agreement between Just One Chesed and you regarding the Just One Chesed
        Service. Any dispute arising from or related to these Terms and Conditions will be governed by the laws of the
        State of New York without regard to conflict of law principles. Any such dispute will be resolved through
        binding arbitration by a single arbitrator pursuant to the American Arbitration Association&apos;s rules
        applicable to commercial disputes. The arbitration will be held in Ithaca, NY. The failure of Just One Chesed to
        exercise or enforce any right or provision of these Terms and Conditions shall not constitute a waiver of such
        right or provision. If any provision of these Terms and Conditions is found to be unenforceable or invalid, that
        provision shall be limited or eliminated to the minimum extent necessary so that these Terms and Conditions
        shall otherwise remain in full force and effect and be enforceable. We may revise these Terms from time to time,
        and the most current version will always be at http://www.Just One Chesed.com/about/terms_and_conditions. If the
        revision, in our sole discretion, is material we will notify you via an e-mail to the email associated with your
        user account. By continuing to access or use the Just One Chesed Service after those revisions become effective,
        you agree to be bound by the revised Terms and Conditions.
      </li>
      <li>
        <b>Survival of Certain Terms Upon Agreement Termination: </b> These Terms and Conditions will remain in full
        force and effect while you use the Just One Chesed Service. Those terms that can continue to operate after you
        stop using the Just One Chesed Service (including without limitation your Content license to Just One Chesed and
        the General Terms in this Section), will survive after you stop using the Just One Chesed Service. You agree to
        indemnify and hold Just One Chesed, its parents, subsidiaries, affiliates, officers and employees, harmless,
        including costs and attorneys&apos; fees, from any claim or demand made by any third party due to or arising out
        of (i) your actions in using the Just One Chesed Service, (ii) a claim that you, or any third party using your
        Account Credentials, infringed any intellectual property or other right of any person or organization using the
        Just One Chesed Service, or (iii) the violation of these Terms and Conditions by you, or any third party using
        your Account Credentials.
      </li>
      <li>
        <b>Assignment and Binding Effect: </b> Customer will not have the right or ability to assign or transfer
        (whether by merger, operation of law or otherwise) the Agreement and these Terms and Conditions, in whole or in
        part, including without limitation any obligations or rights under the Agreement and these Terms without the
        prior written consent of Just One Chesed. A change or control of Customer will be deemed an assignment for
        purposes of this Section. Just One Chesed may assign its rights and obligations under the Agreement and these
        Terms and Conditions without the Customer’s consent. Any assignment or transfer not made in accordance with this
        Section will be void. Without limiting the foregoing, any permitted assigns or successors hereof will be bound
        by all terms and conditions of the Agreement and these Terms and Conditions.
      </li>
      <li>
        <b>Updates to the Terms: </b> Just One Chesed reserves the right to update and change these Terms and Conditions
        from time to time without notice or acceptance by you, so please check this page frequently for updates and
        changes. However, changes made to these Terms and Conditions will not apply to you to the extent that (a) the
        changes concern matters which are the subject of an actual dispute between you and Just One Chesed as of the
        date the changes take effect and (b) Just One Chesed has actual notice of the dispute as of the date the changes
        take effect.
      </li>
    </ol>

    {/* ///////////////////////////// */}

    <h3 className={styles.sub__title}>Just One Chesed Enterprise Platform (formerly Kimbia) - Additional Terms</h3>

    <ol>
      <li>
        <b>International Currency: </b> For any situations in which the calculation of amounts due from Customer to Just
        One Chesed are dependent on the volume of currency transaction processing not denominated in U.S. dollars, for
        that calculation purpose the non-U.S. dollar transaction volume will be converted to U.S. dollars according to
        the historical exchange rate for that currency as of the last day of the month in which the activity in question
        occurred.
      </li>
      <li>
        <b>Management of Customer Website and End Users: </b> Customer represents and warrants to Just One Chesed that
        Customer owns its website, possesses the right to allow integration of the Just One Chesed Service within
        Customer’s website, and that Customer is fully capable of complying with these Terms and the Agreement. With
        respect to the operation and use of Customer’s website: (i) Customer has the sole responsibility for maintaining
        all aspects of its website and keeping it in proper working order at all times, (ii) Customer will comply with
        all applicable laws and sound industry web standards, (iii) Customer will manage the access to and use of its
        website to prevent any unauthorized use of its website and any of the Just One Chesed Service, (iv) Customer
        will take all reasonable steps necessary to immediately stop any unauthorized use of the Just One Chesed Service
        by any end users of its website and upon discovery, Customer will immediately notify Just One Chesed of any
        unauthorized use of the Just One Chesed Service. Customer also understands that, should it enable form widgets
        created by the Just One Chesed Service to be embeddable by others on other websites, Customer is responsible for
        monitoring all activity that passes through these forms. Customer will immediately notify Just One Chesed of any
        violation of these Terms and the Agreement by anyone of which it becomes aware, including violations by its end
        users.
      </li>
      <li>
        <b>Customer Credit Card Processor: </b> Just One Chesed’s Service is designed to interface with Customer’s
        existing credit card processor/gateway account(s) and related merchant accounts (collectively referred to as
        “Processor”) or with Just One Chesed’s preferred vendor Stripe. Fees listed in the Agreement do not include any
        transaction processing fees or any other fees charged by Customer’s Processors. Under no circumstances will Just
        One Chesed be held liable for any Processor activity, including transaction fees, chargeback fees, chargebacks,
        credits, refunds, and other Processor offsets.
        <ul>
          <li>
            Customer is responsible for reconciling Just One Chesed transaction reporting data to Customer’s Processor
            and Customer agrees that Processor reporting should be considered the definitive record of financial
            transactions. Customer represents and warrants that Customer has authorization to provide the Processor
            credentials to Just One Chesed, and that Customer has control over, or has written permission from a third
            party, to exert full control over the Processor credentials Customer provides to Just One Chesed. Customer
            will meet all Processor requirements as applicable related to a posting of its own public privacy statement,
            Customer contact information, or other Processor underwriting and compliance requirements.
          </li>
          <li>
            Just One Chesed is not responsible for (i) misallocation of funds into incorrect accounts due to Customer or
            other third-party error in provision of credential information, or (ii) any missed or foregone transactions
            as a result of payment Processor declines for any reason or as a result of temporary or permanent changes in
            Just One Chesed Service or website availability. While Just One Chesed’s proprietary technology may attempt
            to reduce the amount of fraudulent transactions, Just One Chesed does not guarantee that fraudulent
            transactions will not occur. Any Just One Chesed-recommended practices related to preventing payment fraud
            including manipulation of Processor settings should be evaluated independently by Customer, and Just One
            Chesed is not liable for any loss or other damages that may occur related to such settings. Just One Chesed
            is not responsible for any manipulation of Customer’s Processor account settings.
          </li>
          <li>
            If the Agreement explicitly states that Customer will be making use of a Third Party Foundation approach,
            the Customer will be subject to the Third Party Foundation terms linked to in the Agreement and/or as
            otherwise provided to Customer, and in this case Third Party Foundation terms related to payment
            gateway/merchant account operations, fund disbursement, receipting, and other issues may supplant or
            override these Terms.
          </li>
        </ul>
      </li>
      <li>
        <b>Processor Removal Upon Termination: </b> Upon Termination for Agreement, in addition to the steps taken per
        Section X above, Just One Chesed will remove its connect to the Customer’s Processor and all future transactions
        (scheduled or recurring, for example) will cease at that point. Just One Chesed is not responsible for any
        consequence as a result of Customer or other parties continuing to reference donation forms beyond Agreement
        Termination.
      </li>
      <li>
        <b>Customer Personnel: </b> Customer may use consultants and subcontractors to assist Customer in implementing
        the Just One Chesed Service, exercising Customer’s right to access and use the Just One Chesed Service,
        performing an SOW and otherwise performing Customer’s obligations under the Agreement. Customer will remain
        fully responsible and liable for the performance, actions, and inactions of each such consultants and
        subcontractors (including any payments that may be owed) and for their compliance with all of the terms and
        conditions of the Agreement as if they were Customer’s own employees. Nothing contained in this Agreement will
        create any contractual relationship between Just One Chesed and any Customer consultant or subcontractor.
        Customer will enter into written confidentiality and non-disclosure agreements with each such consultant and
        subcontractor at least as restrictive as the confidentiality and non-disclosure provisions in this Agreement.
        Customer will notify Just One Chesed in advance of the name and contact information for any such consultants or
        subcontractors engaged by Customer to directly interact with Just One Chesed under the Agreement or a specific
        SOW. Just One Chesed reserves the right to require any such consultants or subcontractors to directly enter into
        a confidentiality and nondisclosure agreement with Just One Chesed.
      </li>
    </ol>

    <hr />

    <div className={styles.gray__text}>
      <i>Effective 6/1/2015. Last Updated 11/01/2018</i>
    </div>
  </div>
);

export default TermsInfo;
